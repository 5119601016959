import { resetEmbalmingAddDataForm, setEmbalmingsTotalLineToPagination } from './embalmingSlice'
import { resetAddressBook } from '@redux/features/addressBook'
import { adminApi } from '@redux/rtk/api'
import { notification } from 'antd'
import * as Sentry from '@sentry/nextjs'

const embalmingApi = adminApi.injectEndpoints({
    endpoints: (builder) => ({
        getEmbalmings: builder.query({
            query: (params) => ({
                url: `admin/service_embalming/`,
                method: 'GET',
                params,
            }),
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result?.results?.map(({ id }) => ({ type: 'Embalming', id })),
                          { type: 'Embalming', id: 'LIST' },
                      ]
                    : [{ type: 'Embalming', id: 'LIST' }],

            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const result = await queryFulfilled

                    const { data } = result

                    dispatch(setEmbalmingsTotalLineToPagination(data.count))
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
        }),

        createEmbalming: builder.mutation({
            query: (embalming) => ({
                url: `admin/service_embalming/`,
                method: 'POST',
                body: embalming,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Funeral' }],

            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled

                    dispatch(resetAddressBook())
                    dispatch(resetEmbalmingAddDataForm())
                } catch (err) {}
            },
        }),

        getEmbalming: builder.query({
            query: (id) => ({
                url: `admin/service_embalming/${id}`,
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: 'Embalming', id }],
        }),

        updateEmbalming: builder.mutation({
            query: ({ data, id }) => ({
                url: `admin/service_embalming/${id}/`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Embalming', id: arg.id }],

            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled

                    const notificationOptions = {
                        message: 'Thanatopraxie modifiée avec succès',
                        duration: 2,
                    }
                    notification.success(notificationOptions)
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
        }),

        updateEmbalmingStatus: builder.mutation({
            query: ({ data, id }) => ({
                url: `admin/service_embalming/${id}/status/`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Embalming', id: arg.id },
                { type: 'Embalming', id: 'LIST' },
            ],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled

                    notification.success({ message: 'Statut mis à jours avec succès', duration: 2 })
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
        }),

        updateEmbalmingResponsible: builder.mutation({
            query: ({ data, id }) => ({
                url: `admin/service_embalming/${id}/responsible/`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Embalming', id: arg.id },
                { type: 'Embalming', id: 'LIST' },
            ],

            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled

                    notification.success({
                        message: 'Responsable mis à jours avec succès',
                        duration: 2,
                    })
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
        }),

        deleteEmbalming: builder.mutation({
            query: (id) => ({
                url: `admin/service_embalming/${id}/`,
                method: 'DELETE',
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled

                    notification.success({
                        message: 'Service thanatopraxie supprimé',
                        duration: 1,
                    })
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
            invalidatesTags: (result, error, arg) => [
                { type: 'Embalming', id: arg.id },
                { type: 'Funeral', id: arg.id },
            ],
        }),
        deleteEmbalmingFile: builder.mutation({
            query: (id) => ({
                url: `admin/medias/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Funeral', id: arg.id },
                { type: 'Embalming', id: arg.id },
            ],
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetEmbalmingsQuery,
    useCreateEmbalmingMutation,
    useGetEmbalmingQuery,
    useUpdateEmbalmingMutation,
    useUpdateEmbalmingStatusMutation,
    useUpdateEmbalmingResponsibleMutation,
    useDeleteEmbalmingMutation,
    useDeleteEmbalmingFileMutation,
} = embalmingApi
