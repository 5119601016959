export let allRoutes = [
    {
        icon: <i className="fa-light fa-gauge" />,
        label: 'Tableau de bord',
        path: '/',
    },
    {
        icon: <i className="fa-light fa-folder" />,
        label: 'Obsèques',
        path: '/funerals',
        children: [
            {
                type: 'group',
                label: 'Obsèques',
                path: '/funerals',
                children: [
                    {
                        path: '/funerals/planning',
                        label: 'Planning',
                        description: 'Planning des obsèques à venir et passées.',
                    },
                    {
                        path: '/funerals',
                        label: 'Liste',
                    },
                    {
                        path: '/funerals/archived',
                        label: 'Terminées',
                    },
                ],
            },
        ],
    },
    {
        icon: <i className="fa-light fa-square-list" />,
        label: 'Activités',
        path: '/orders/transfers',
        children: [
            {
                type: 'group',
                label: 'Activités',
                path: '/orders/transfers',
                children: [
                    {
                        path: '/orders/transfers',
                        label: 'Transferts',
                    },
                    {
                        path: '/orders/procedures',
                        label: 'Démarches',
                    },
                    {
                        path: '/orders/embalmings',
                        label: 'Thanatopraxies',
                    },
                    {
                        path: '/orders/convoys',
                        label: 'Convois - liste',
                    },
                    {
                        path: '/orders/convoys/planning',
                        label: 'Convois - planning',
                    },
                ],
            },
        ],
    },
    {
        icon: <i className="fa-light fa-people-group" />,
        label: 'Disponibilités',
        path: '/team/availabilities',
        children: [
            {
                type: 'group',
                label: 'Equipes',
                path: '/team/availabilities',
                children: [
                    {
                        path: '/team/availabilities',
                        label: 'Disponibilités',
                    },
                ],
            },
        ],
    },
    {
        icon: <i className="fa-light fa-shop" />,
        label: 'Entreprises',
        path: '/companies',
        children: [
            {
                type: 'group',
                label: 'Entreprises',
                path: '/companies',
                children: [
                    {
                        path: '/companies',
                        label: 'Entreprises',
                    },
                    {
                        path: '/companies/agencies',
                        label: 'Agences',
                    },
                    {
                        path: '/companies/agents',
                        label: 'Conseillers',
                    },
                ],
            },
        ],
    },
    {
        icon: <i className="fa-light fa-users" />,
        label: 'Personnels',
        path: '/personals/users',
        children: [
            {
                type: 'group',
                label: 'Personnels',
                path: '/personals/users',
                children: [
                    {
                        path: '/personals/users',
                        label: 'Utilisateurs',
                    },
                    {
                        path: '/personals/groups',
                        label: 'Groupes',
                    },
                ],
            },
        ],
    },
]
