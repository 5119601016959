import { adminApi } from '@redux/rtk/api'
import { setIsAuth, setPermissions } from './authSlice'
import { persistor } from '@redux/store'
import { notification } from 'antd'
import * as Sentry from '@sentry/nextjs'

const authApi = adminApi.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: 'auth/funovia/login/',
                method: 'POST',
                body: credentials,
            }),

            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const result = await queryFulfilled

                    const { data } = result

                    localStorage.setItem(`funovia-admin-token`, data.token)

                    dispatch(setIsAuth(true))

                    dispatch(authApi.endpoints.getMe.initiate())
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
        }),
        logout: builder.mutation({
            query: () => ({
                url: 'auth/funovia/logout/',
                method: 'GET',
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled

                    localStorage.removeItem(`funovia-admin-token`)

                    dispatch(setIsAuth(false))

                    // Reset all endpoints
                    dispatch(adminApi.util.resetApiState())

                    // Reset all reducers
                    persistor.flush()
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
        }),
        getMe: builder.query({
            query: () => ({
                url: 'auth/funovia/me/',
                method: 'GET',
            }),
            providesTags: (result, error) => [{ type: 'Me' }],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    dispatch(setPermissions(data.permissions))
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
        }),
        updateMe: builder.mutation({
            query: (data) => ({
                url: 'auth/funovia/me/',
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: [{ type: 'Me' }],
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled

                    const notificationOptions = {
                        message: 'Profil mis à jours avec succès',
                        duration: 1,
                    }
                    notification.success(notificationOptions)
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
        }),
        deleteMeAvatar: builder.mutation({
            query: () => ({
                url: 'auth/funovia/me/avatar/',
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Me' }],
        }),
    }),
    overrideExisting: true,
})

export const {
    useLoginMutation,
    useLogoutMutation,
    useGetMeQuery,
    useUpdateMeMutation,
    useHelpMeMutation,
    useDeleteMeAvatarMutation,
} = authApi
