import {
    AUTHORITY_RELATION,
    CEREMONY_TYPES,
    FUNERAL_TYPES,
    ORDERS_STATUSES,
} from '@common/constants'
import { addDisabledToFields } from '@helpers/permissions'
import {
    resetAddDataOrderForm,
    resetListActualFilter,
    setAddDataOrderForm,
    setListActualFilter,
    setListPagination,
    setOptionsToAgencyField,
    setOptionsToResponsibleField,
    setTotalInPagination,
} from '@redux/common'
import { createSelector, createSlice } from '@reduxjs/toolkit'

const initialState = {
    addData: null,
    addFields: {
        autorityPower: [
            {
                name: 'authority_firstname',
                label: 'Prénom',
                component: 'text',
            },
            {
                name: 'authority_lastname',
                label: 'Nom',
                component: 'text',
            },
            {
                name: 'authority_phone',
                label: 'Tél.',
                component: 'phone',
            },
            {
                name: 'authority_relationship',
                label: 'Lien de parenté',
                placeholder: 'Selectionner un lien',
                component: 'select',
                options: AUTHORITY_RELATION,
            },
        ],

        bury: {
            info_left: [
                {
                    name: 'bury_with_family',
                    label: 'Présence de la famille',
                    component: 'boolean',
                    labelCol: 24,
                },
            ],
            info_right: [
                {
                    name: 'bury_with_police',
                    label: 'Présence de la police',
                    component: 'boolean',
                    labelCol: 24,
                },
            ],

            right: {
                date: [
                    {
                        name: 'bury_date',
                        label: 'Date',
                        component: 'date',
                        placeholder: 'Selectionner une date',
                        format: 'DD/MM/YYYY',
                        disabled: true,
                    },

                    {
                        name: 'bury_start_time',
                        label: 'MB',
                        component: 'time',
                        placeholder: 'Selectionner une heure',
                        format: 'HH:mm',
                    },
                    {
                        name: 'bury_end_time',
                        label: 'Départ',
                        component: 'time',
                        placeholder: 'Selectionner une heure',
                        format: 'HH:mm',
                    },
                ],
            },
        },

        ceremony: [
            {
                name: 'ceremony_datetime',
                label: 'Date et heure',
                component: 'datetime',
                placeholder: 'Selectionner une date',
                format: 'DD/MM/YYYY HH:mm',
            },
            {
                name: 'ceremony_type',
                component: 'radio',
                options: CEREMONY_TYPES,
            },
        ],

        funeral: [
            {
                name: 'funeral_datetime',
                label: 'Date et heure',
                component: 'datetime',
                placeholder: 'Selectionner une date',
                format: 'DD/MM/YYYY HH:mm',
            },
            {
                name: 'funeral_type',
                label: 'Type de cérémonie',
                component: 'radio',
                options: FUNERAL_TYPES,
            },
        ],

        files: [
            {
                name: 'medias',
                component: 'file',
                labelCol: 24,
                acceptedTypes: [
                    'image/png',
                    'image/jpeg',
                    'image/jpg',
                    'image/webm',
                    'application/pdf',
                ],
            },
        ],
    },

    editData: null,
    editFields: {
        requiredPermissions: [['core.ServiceConvoy', 'change_serviceconvoy']],

        autorityPower: [
            {
                name: 'authority_firstname',
                label: 'Prénom',
                component: 'text',
            },
            {
                name: 'authority_lastname',
                label: 'Nom',
                component: 'text',
            },
            {
                name: 'authority_phone',
                label: 'Tél.',
                component: 'phone',
            },
            {
                name: 'authority_relationship',
                label: 'Lien de parenté',
                placeholder: 'Selectionner un lien',
                component: 'select',
                options: AUTHORITY_RELATION,
            },
        ],

        bury: {
            info_left: [
                {
                    name: 'bury_with_family',
                    label: 'Présence de la famille',
                    component: 'boolean',
                    labelCol: 24,
                },
            ],
            info_right: [
                {
                    name: 'bury_with_police',
                    label: 'Présence de la police',
                    component: 'boolean',
                    labelCol: 24,
                },
            ],

            right: {
                date: [
                    {
                        name: 'bury_date',
                        label: 'Date',
                        component: 'date',
                        placeholder: 'Selectionner une date',
                        format: 'DD/MM/YYYY',
                        disabled: true,
                    },

                    {
                        name: 'bury_start_time',
                        label: 'MB',
                        component: 'time',
                        placeholder: 'Selectionner une heure',
                        format: 'HH:mm',
                    },
                    {
                        name: 'bury_end_time',
                        label: 'Départ',
                        component: 'time',
                        placeholder: 'Selectionner une heure',
                        format: 'HH:mm',
                    },
                ],
            },
        },

        ceremony: [
            {
                name: 'ceremony_datetime',
                label: 'Date et heure',
                component: 'datetime',
                placeholder: 'Selectionner une date',
                format: 'DD/MM/YYYY HH:mm',
            },
            {
                name: 'ceremony_type',
                component: 'radio',
                options: CEREMONY_TYPES,
            },
        ],

        funeral: [
            {
                name: 'funeral_datetime',
                label: 'Date et heure',
                component: 'datetime',
                placeholder: 'Selectionner une date',
                format: 'DD/MM/YYYY HH:mm',
            },
            {
                name: 'funeral_type',
                label: 'Type de cérémonie',
                component: 'radio',
                options: FUNERAL_TYPES,
            },
        ],

        files: [
            {
                name: 'medias',
                component: 'file',
                labelCol: 24,
                acceptedTypes: [
                    'image/png',
                    'image/jpeg',
                    'image/jpg',
                    'image/webm',
                    'application/pdf',
                ],
            },
        ],
    },
    filters: {
        fields: {
            fastSearch: [
                {
                    name: 'search',
                    placeholder: 'recherche rapide (Défunt, responsable, …)',
                    component: 'search',
                    withStatus: false,
                },
            ],
            drawer: [
                {
                    name: 'start_at',
                    component: 'dateRange',
                    label: 'Date de départ',
                    labelCol: 24,
                    labelIcon: 'calendar-alt',
                    placeholder: ['Du', 'au'],
                    format: 'DD/MM/YYYY',
                    options: [],
                    withStatus: false,
                },
                {
                    name: 'service_status',
                    label: 'Statuts des services',
                    labelCol: 24,
                    labelIcon: 'cube',
                    component: 'multiSelect',
                    placeholder: 'Selectionner des status',
                    options: ORDERS_STATUSES,
                    withStatus: false,
                },
                {
                    name: 'agency',
                    labelCol: 24,
                    labelIcon: 'shop',
                    label: 'Agences',
                    placeholder: 'Sélectionnez des agences',
                    component: 'multiSelect',
                    options: [],
                    withStatus: false,
                },
                {
                    name: 'responsible',
                    labelCol: 24,
                    labelIcon: 'shop',
                    label: 'Responsables',
                    placeholder: 'Sélectionnez des responsables',
                    component: 'multiSelect',
                    options: [],
                    withStatus: false,
                },
            ],
        },
        actual: {},
    },

    pagination: {
        page: 1,
        size: 15,
        total: 0,
    },
}

const convoySlice = createSlice({
    name: 'convoy',
    initialState,
    reducers: {
        // FILTERS
        setConvoysFilters: setListActualFilter,
        resetConvoysFilters: resetListActualFilter,

        // PAGINATION
        setConvoysPagination: setListPagination,
        setConvoysTotalLineToPagination: setTotalInPagination,

        // FORM
        setConvoyAddDataForm: setAddDataOrderForm,
        resetConvoyAddDataForm: resetAddDataOrderForm,

        setConvoyEditDataForm: (state, action) => {
            state.editData = action.payload
        },
        resetConvoyEditDataForm: (state) => {
            state.editData = null
        },

        // SET OPTIONS:
        setConvoysAgenciesOptions: setOptionsToAgencyField,
        setResponsiblesOptions: setOptionsToResponsibleField,
    },
})

export const {
    // FILTERS
    setConvoysFilters,
    resetConvoysFilters,

    // PAGINATION
    setConvoysPagination,
    setConvoysTotalLineToPagination,

    // FORM
    setConvoyAddDataForm,
    resetConvoyAddDataForm,
    setConvoyEditDataForm,
    resetConvoyEditDataForm,

    // SET OPTIONS:
    setConvoysAgenciesOptions,
    setResponsiblesOptions,
} = convoySlice.actions
export default convoySlice.reducer

// Root selector
const convoySelector = (state) => state.convoy
const authSelector = (state) => state.auth

// FILTERS
export const selectConvoysFieldsFilters = createSelector(
    convoySelector,
    (convoyState) => convoyState.filters.fields
)

export const selectConvoysActualFilters = createSelector(
    convoySelector,
    (convoyState) => convoyState.filters.actual
)

export const selectConvoysNumberOfActualFilters = createSelector(convoySelector, (convoyState) => {
    const countWithoutOrderingAndSearch = _.omit(convoyState.filters?.actual, [
        'search',
        'ordering',
    ])

    // Get number of filter who's value is not empty or null and object filtered
    const countWithoutEmptyArrayOrNullishValue = Object.keys(
        _.omitBy(countWithoutOrderingAndSearch, (value) => value?.length === 0 || _.isNil(value))
    ).length

    return countWithoutEmptyArrayOrNullishValue
})

// PAGINATION
export const selectConvoysPagination = createSelector(
    convoySelector,
    (convoyState) => convoyState.pagination
)

export const selectConvoysParamsPagination = createSelector(convoySelector, (convoyState) =>
    _.omit(convoyState.pagination, ['total'])
)

// CREATE
export const selectConvoyFieldsAdd = createSelector(
    convoySelector,
    (convoyState) => convoyState.addFields
)

export const selectConvoyAddData = createSelector(
    convoySelector,
    (convoyState) => convoyState.addData
)

// EDIT
export const selectConvoyFieldsEdit = createSelector(
    convoySelector,
    authSelector,
    (convoyState, authState) => addDisabledToFields(convoyState.editFields, authState)
)

export const selectConvoyEditData = createSelector(
    convoySelector,
    (convoyState) => convoyState.editData
)
