import { API_ROUTE, VERSION } from '@constants'
import { fetchBaseQuery, retry } from '@reduxjs/toolkit/query'
import { createApi } from '@reduxjs/toolkit/query/react'
import queryString from 'query-string'

export const adminApi = createApi({
    reducerPath: 'adminApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${API_ROUTE}/${VERSION}/`,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem(`funovia-admin-token`)

            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('authorization', `Token ${token}`)
            }

            return headers
        },
        paramsSerializer: (params) => {
            return queryString.stringify(params, {
                skipEmptyString: true,
                skipNull: true,
                strict: false,
            })
        },
        timeout: 10000,
    }),
    tagTypes: [
        'Login',
        'Me',
        'Company',
        'Group',
        'FunoviaUser',
        'Agency',
        'SelectAgents',
        'SelectAgencies',
        'SelectCompanies',
        'Agent',
        'Transfer',
        'Procedure',
        'Embalming',
        'Convoy',
        'AddressBook',
        'SelectResponsibles',
        'Funeral',
        'FuneralPlanning',
        'ConvoyPlanning',
        'StatsDashboard',
    ],
    endpoints: (builder) => ({
        globalSearch: builder.query({
            query: (params) => ({
                url: `admin/search/`,
                method: 'GET',
                params,
            }),
        }),
    }),
})

export const { endpoints, reducerPath, reducer, middleware, useGlobalSearchQuery } = adminApi
