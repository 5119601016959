import { EMBALMING_CLOTHING_ON_SITE, ORDERS_STATUSES, EMBALMING_TYPES } from '@common/constants'
import { addDisabledToFields } from '@helpers/permissions'
import {
    resetAddDataOrderForm,
    resetListActualFilter,
    setAddDataOrderForm,
    setListActualFilter,
    setListPagination,
    setOptionsToAgencyField,
    setOptionsToResponsibleField,
    setTotalInPagination,
} from '@redux/common'

import { createSelector, createSlice } from '@reduxjs/toolkit'

const initialState = {
    addFields: {
        intervention: [
            {
                name: 'intervention_date',
                label: 'Date et heure',
                component: 'datetime',
                placeholder: 'Selectionner une date',
                format: 'DD/MM/YYYY HH:mm',
            },
        ],
        additional_detail_center: [
            {
                name: 'type',
                label: 'Prestation',
                component: 'radio',
                options: EMBALMING_TYPES,
                labelCol: 24,
            },
            {
                name: 'pacemaker',
                label: 'Pacemaker',
                component: 'boolean',
                labelCol: 24,
            },
            {
                name: 'exhibition',
                label: 'Salon permanent',
                component: 'boolean',
                labelCol: 24,
            },
        ],
        additional_detail_right: [
            {
                name: 'clothing_on_site',
                label: 'Vêtements sur place',
                component: 'radio',
                options: EMBALMING_CLOTHING_ON_SITE,
                labelCol: 24,
            },
            {
                name: 'clothing_comments',
                placeholder: 'Où sont les vêtements ?',
                component: 'textaera',
                rows: 2,
                labelCol: 24,
            },
            {
                name: 'site_visit',
                label: 'Visite de la famille',
                component: 'boolean',
                labelCol: 24,
            },
        ],
        files: [
            {
                name: 'medias',
                component: 'file',
                labelCol: 24,
                acceptedTypes: [
                    'image/png',
                    'image/jpeg',
                    'image/jpg',
                    'image/webm',
                    'application/pdf',
                ],
            },
        ],
    },
    editFields: {
        requiredPermissions: [['core.ServiceEmbalming', 'change_serviceembalming']],

        intervention: [
            {
                name: 'intervention_date',
                label: 'Date et heure',
                component: 'datetime',
                placeholder: 'Selectionner une date',
                format: 'DD/MM/YYYY HH:mm',
            },
        ],
        additional_detail_center: [
            {
                name: 'type',
                label: 'Prestation',
                component: 'radio',
                options: EMBALMING_TYPES,
                labelCol: 24,
            },
            {
                name: 'pacemaker',
                label: 'Pacemaker',
                component: 'boolean',
                labelCol: 24,
            },
            {
                name: 'exhibition',
                label: 'Salon permanent',
                component: 'boolean',
                labelCol: 24,
            },
        ],
        additional_detail_right: [
            {
                name: 'clothing_on_site',
                label: 'Vêtements sur place',
                component: 'radio',
                options: EMBALMING_CLOTHING_ON_SITE,
                labelCol: 24,
            },
            {
                name: 'clothing_comments',
                placeholder: 'Où sont les vêtements ?',
                component: 'textaera',
                rows: 2,
                labelCol: 24,
            },
            {
                name: 'site_visit',
                label: 'Visite de la famille',
                component: 'boolean',
                labelCol: 24,
            },
        ],
        files: [
            {
                name: 'medias',
                component: 'file',
                labelCol: 24,
                acceptedTypes: [
                    'image/png',
                    'image/jpeg',
                    'image/jpg',
                    'image/webm',
                    'application/pdf',
                ],
            },
        ],
    },

    filters: {
        fields: {
            fastSearch: [
                {
                    name: 'search',
                    placeholder: 'recherche rapide (Défunt, responsable, …)',
                    component: 'search',
                    withStatus: false,
                },
            ],
            drawer: [
                {
                    name: 'start_at',
                    component: 'dateRange',
                    label: 'Date de la thanatopraxie',
                    labelCol: 24,
                    labelIcon: 'calendar-alt',
                    placeholder: ['Du', 'au'],
                    format: 'DD/MM/YYYY',
                    options: [],
                    withStatus: false,
                },
                {
                    name: 'service_status',
                    label: 'Statuts des services',
                    labelCol: 24,
                    labelIcon: 'cube',
                    component: 'multiSelect',
                    placeholder: 'Selectionner des status',
                    options: ORDERS_STATUSES,
                    withStatus: false,
                },
                {
                    name: 'agency',
                    labelCol: 24,
                    labelIcon: 'shop',
                    label: 'Agences',
                    placeholder: 'Sélectionnez des agences',
                    component: 'multiSelect',
                    options: [],
                    withStatus: false,
                },
                {
                    name: 'responsible',
                    labelCol: 24,
                    labelIcon: 'shop',
                    label: 'Responsables',
                    placeholder: 'Sélectionnez des responsables',
                    component: 'multiSelect',
                    options: [],
                    withStatus: false,
                },
            ],
        },
        actual: {},
    },

    pagination: {
        page: 1,
        size: 15,
        total: 0,
    },
}

const embalmingSlice = createSlice({
    name: 'embalming',
    initialState,
    reducers: {
        // FILTERS
        setEmbalmingsFilters: setListActualFilter,
        resetEmbalmingsFilters: resetListActualFilter,

        // PAGINATION
        setEmbalmingsPagination: setListPagination,
        setEmbalmingsTotalLineToPagination: setTotalInPagination,

        // FORM
        setEmbalmingAddDataForm: setAddDataOrderForm,
        resetEmbalmingAddDataForm: resetAddDataOrderForm,

        // SET OPTIONS:
        setEmbalmingsAgenciesOptions: setOptionsToAgencyField,
        setResponsiblesOptions: setOptionsToResponsibleField,
    },
})

export const {
    // FILTERS
    setEmbalmingsFilters,
    resetEmbalmingsFilters,

    // PAGINATION
    setEmbalmingsPagination,
    setEmbalmingsTotalLineToPagination,

    // FORM
    setEmbalmingAddDataForm,
    resetEmbalmingAddDataForm,

    // SET OPTIONS:
    setEmbalmingsAgenciesOptions,
    setResponsiblesOptions,
} = embalmingSlice.actions
export default embalmingSlice.reducer

// Root selector
const embalmingSelector = (state) => state.embalming
const authSelector = (state) => state.auth

// FILTERS
export const selectEmbalmingsFieldsFilters = createSelector(
    embalmingSelector,
    (embalmingState) => embalmingState.filters.fields
)

export const selectEmbalmingsActualFilters = createSelector(
    embalmingSelector,
    (embalmingState) => embalmingState.filters.actual
)

export const selectEmbalmingsNumberOfActualFilters = createSelector(
    embalmingSelector,
    (embalmingState) => {
        const countWithoutOrderingAndSearch = _.omit(embalmingState.filters?.actual, [
            'search',
            'ordering',
        ])

        // Get number of filter who's value is not empty or null and object filtered
        const countWithoutEmptyArrayOrNullishValue = Object.keys(
            _.omitBy(
                countWithoutOrderingAndSearch,
                (value) => value?.length === 0 || _.isNil(value)
            )
        ).length

        return countWithoutEmptyArrayOrNullishValue
    }
)

// PAGINATION
export const selectEmbalmingsPagination = createSelector(
    embalmingSelector,
    (embalmingState) => embalmingState.pagination
)

export const selectEmbalmingsParamsPagination = createSelector(
    embalmingSelector,
    (embalmingState) => _.omit(embalmingState.pagination, ['total'])
)

// CREATE
export const selectEmbalmingFieldsAdd = createSelector(
    embalmingSelector,
    (embalmingState) => embalmingState.addFields
)

export const selectEmbalmingAddData = createSelector(
    embalmingSelector,
    (embalmingState) => embalmingState.addData
)

// EDIT
export const selectEmbalmingsFieldsEdit = createSelector(
    embalmingSelector,
    authSelector,
    (embalmingState, authState) => addDisabledToFields(embalmingState.editFields, authState)
)
