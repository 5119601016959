// This files contain common reducers

// All reducer are prefixed with the current action and the type of page (list, add, edit, delete)

// Example: <action><type><reducer>

// For using this common reducer. Your slice need to be the same or create a second function
import * as Sentry from '@sentry/nextjs'

export const setListPagination = (state, action) => {
    state.pagination.page = action.payload
    // state.pagination = action.payload

    return state
}

export const setListActualFilter = (state, action) => {
    state.filters.actual = action.payload

    return state
}

export const resetListActualFilter = (state, action) => {
    state.filters.actual = {}

    return state
}

export const removeListFieldInFilter = (state, action) => {
    delete state.actualFilters[action.payload]

    return state
}

// Il will be used for add data form to display it in summary
// Usage for the 4 order
export const setAddDataOrderForm = (state, action) => {
    state.addData = action.payload

    return state
}

export const resetAddDataOrderForm = (state, action) => {
    state.addData = null

    return state
}

export const setTotalInPagination = (state, action) => {
    state.pagination.total = action.payload

    return state
}

export const setOptionsToAgentField = (state, action) => {
    action?.payload?.fieldsType?.forEach((fieldType) => {
        if (fieldType === 'filter') {
            const agentFieldIndex = state.filters.fields.drawer.findIndex((e) => e.name === 'agent')

            if (agentFieldIndex !== -1) {
                state.filters.fields.drawer[agentFieldIndex].options = action.payload.options
            } else {
                Sentry.captureException(new Error('-- agent -- field not found in filter fields '))
            }
        } else if (fieldType === 'create') {
            const agentFieldIndex = state.addFields.agent.findIndex(
                (e) => e.name === 'agent' || e?.privateKey === 'agent'
            )

            if (agentFieldIndex !== -1) {
                state.addFields.agent[agentFieldIndex].options = action.payload.options
            } else {
                Sentry.captureException(new Error('-- agent -- field not found in addFiels '))
            }
        } else if (fieldType === 'edit') {
            const agentFieldIndex = state.editFields.agent.findIndex(
                (e) => e.name === 'agent' || e?.privateKey === 'agent'
            )

            if (agentFieldIndex !== -1) {
                state.editFields.agent[agentFieldIndex].options = action.payload.options
            } else {
                Sentry.captureException(new Error('-- agent -- field not found in editFiels '))
            }
        }
    })

    return state
}

export const setOptionsToCompanyField = (state, action) => {
    action?.payload?.fieldsType?.forEach((fieldType) => {
        if (fieldType === 'filter') {
            const companyFieldIndex = state.filters.fields.drawer.findIndex(
                (e) => e.name === 'company'
            )
            if (companyFieldIndex !== -1) {
                state.filters.fields.drawer[companyFieldIndex].options = action.payload.options
            } else {
                Sentry.captureException(
                    new Error('-- company -- field not found in filter fields ')
                )
            }
        } else if (fieldType === 'create') {
            const companyFieldIndex = state.addFields.company.findIndex(
                (e) => e.name === 'company' || e?.privateKey === 'company'
            )
            if (companyFieldIndex !== -1) {
                state.addFields.company[companyFieldIndex].options = action.payload.options
            } else {
                Sentry.captureException(new Error('-- company -- field not found in addFiels '))
            }
        } else if (fieldType === 'edit') {
            const companyFieldIndex = state.editFields.company.findIndex(
                (e) => e.name === 'company' || e?.privateKey === 'company'
            )
            if (companyFieldIndex !== -1) {
                state.editFields.company[companyFieldIndex].options = action.payload.options
            } else {
                Sentry.captureException(new Error('-- company -- field not found in editFiels '))
            }
        }
    })

    return state
}

export const setOptionsToGroupField = (state, action) => {
    action?.payload?.fieldsType?.forEach((fieldType) => {
        if (fieldType === 'filter') {
            const groupFieldIndex = state.filters.fields.drawer.findIndex((e) => e.name === 'group')
            if (groupFieldIndex !== -1) {
                state.filters.fields.drawer[groupFieldIndex].options = action.payload.options
            } else {
                Sentry.captureException(new Error('-- group -- field not found in filter fields '))
            }
        } else if (fieldType === 'create') {
            const groupFieldIndex = state.addFields.group.findIndex(
                (e) => e.name === 'group' || e?.privateKey === 'group'
            )
            if (groupFieldIndex !== -1) {
                state.addFields.group[groupFieldIndex].options = action.payload.options
            } else {
                Sentry.captureException(new Error('-- group -- field not found in addFiels '))
            }
        }
    })

    return state
}

export const setOptionsToAgencyField = (state, action) => {
    action?.payload?.fieldsType?.forEach((fieldType) => {
        if (fieldType === 'filter') {
            const agencyFieldIndex = state.filters.fields.drawer.findIndex(
                (e) => e.name === 'agency'
            )
            if (agencyFieldIndex !== -1) {
                state.filters.fields.drawer[agencyFieldIndex].options = action.payload.options
            } else {
                Sentry.captureException(new Error('-- agency -- field not found in filter fields '))
            }
        } else if (fieldType === 'create') {
            const agencyFieldIndex = state.addFields.agency.findIndex((e) => e.name === 'agency')
            if (agencyFieldIndex !== -1) {
                state.addFields.agency[agencyFieldIndex].options = action.payload.options
            } else {
                Sentry.captureException(new Error('-- agency -- field not found in addFiels '))
            }
        } else if (fieldType === 'edit') {
            const agencyFieldIndex = state.editFields.agency.findIndex(
                (e) => e.name === 'agency' || e?.privateKey === 'agency'
            )

            if (agencyFieldIndex !== -1) {
                state.editFields.agency[agencyFieldIndex].options = action.payload.options
            } else {
                Sentry.captureException(new Error('-- agency -- field not found in editFiels '))
            }
        }
    })

    return state
}

export const setOptionsToResponsibleField = (state, action) => {
    action?.payload?.fieldsType?.forEach((fieldType) => {
        if (fieldType === 'filter') {
            const responsibleFieldIndex = state.filters.fields.drawer.findIndex(
                (e) => e.name === 'responsible'
            )
            if (responsibleFieldIndex !== -1) {
                state.filters.fields.drawer[responsibleFieldIndex].options = action.payload.options
            } else {
                Sentry.captureException(
                    new Error('-- responsible -- field not found in filter fields ')
                )
            }
        } else if (fieldType === 'create') {
            const responsibleFieldIndex = state.addFields.responsible.findIndex(
                (e) => e.name === 'responsible'
            )
            if (responsibleFieldIndex !== -1) {
                state.addFields.responsible[responsibleFieldIndex].options = action.payload.options
            } else {
                Sentry.captureException(new Error('-- responsible -- field not found in addFiels '))
            }
        }
    })

    return state
}
