import { addDisabledToFields } from '@helpers/permissions'
import { setListActualFilter, setListPagination, setTotalInPagination } from '@redux/common'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState = {
    addFields: {
        details: [
            {
                name: 'name',
                label: 'Nom',
                placeholder: "Nom de l'entreprise",
                component: 'text',
            },
            {
                name: 'email',
                label: 'Email',
                placeholder: 'mon-entreprise@gmail.com',
                component: 'text',
            },
            {
                name: 'phone',
                label: 'Tél.',
                placeholder: "Numéro de l'entreprise",
                component: 'phone',
            },
            {
                name: 'siret',
                label: 'SIRET',
                placeholder: '1038519364290135',
                component: 'text',
            },
        ],
        address: [
            {
                name: 'address',
                component: 'address',
                nestedFields: ['address', 'address_2', 'city', 'zipcode'],
            },
        ],
    },
    editFields: {
        requiredPermissions: [['core.Company', 'change_company']],

        details: [
            {
                name: 'name',
                label: 'Nom',
                placeholder: "Nom de l'entreprise",
                component: 'text',
            },
            {
                name: 'email',
                label: 'Email',
                placeholder: 'mon-entreprise@gmail.com',
                component: 'text',
            },
            {
                name: 'phone',
                label: 'Tél.',
                placeholder: "Numéro de l'agence",
                component: 'phone',
            },
            {
                name: 'siret',
                label: 'SIRET',
                placeholder: '1038519364290135',
                component: 'text',
            },
        ],
        address: [
            {
                name: 'address',
                component: 'address',
                nestedFields: ['address', 'address_2', 'city', 'zipcode'],
            },
        ],
    },

    filters: {
        fields: {
            fastSearch: [
                {
                    name: 'search',
                    placeholder: 'recherche rapide (Nom, adresse, ville, …)',
                    component: 'search',
                    withStatus: false,
                },
            ],
        },
        actual: {},
    },

    pagination: {
        page: 1,
        size: 15,
        total: 0,
    },
}

const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        // FILTERS
        setCompaniesFilters: setListActualFilter,

        // PAGINATION
        setCompaniesPagination: setListPagination,
        setCompaniesTotalLineToPagination: setTotalInPagination,
    },
})

export const { setCompaniesFilters, setCompaniesPagination, setCompaniesTotalLineToPagination } =
    companySlice.actions
export default companySlice.reducer

// Root selector
const companySelector = (state) => state.company
const authSelector = (state) => state.auth

// FILTERS
export const selectCompaniesFieldsFilters = createSelector(
    companySelector,
    (companyState) => companyState.filters.fields
)

export const selectCompaniesActualFilters = createSelector(
    companySelector,
    (companyState) => companyState.filters.actual
)

// CREATE
export const selectCompanyAddFields = createSelector(
    companySelector,
    (companyState) => companyState.addFields
)

// EDIT

export const selectCompanyEditFields = createSelector(
    companySelector,
    authSelector,
    (companyState, authState) => addDisabledToFields(companyState.editFields, authState)
)

// PAGINATION
export const selectCompaniesPagination = createSelector(
    companySelector,
    (companyState) => companyState.pagination
)

export const selectCompaniesParamsPagination = createSelector(companySelector, (companyState) =>
    _.omit(companyState.pagination, ['total'])
)
