import {
    resetConvoyAddDataForm,
    setConvoyAddDataForm,
    setConvoysTotalLineToPagination,
} from './convoySlice'
import { resetAddressBook } from '@redux/features/addressBook'
import { adminApi } from '@redux/rtk/api'
import { notification } from 'antd'
import * as Sentry from '@sentry/nextjs'

const embalmingApi = adminApi.injectEndpoints({
    endpoints: (builder) => ({
        getConvoys: builder.query({
            query: (params) => ({
                url: `admin/service_convoys/`,
                method: 'GET',
                params,
            }),
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result?.results?.map(({ id }) => ({ type: 'Convoy', id })),
                          { type: 'Convoy', id: 'LIST' },
                      ]
                    : [{ type: 'Convoy', id: 'LIST' }],

            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const result = await queryFulfilled

                    const { data } = result

                    dispatch(setConvoysTotalLineToPagination(data.count))
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
        }),

        createConvoy: builder.mutation({
            query: (embalming) => ({
                url: `admin/service_convoys/`,
                method: 'POST',
                body: embalming,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Funeral' }],

            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled

                    dispatch(resetAddressBook())
                    dispatch(resetConvoyAddDataForm())
                } catch (err) {}
            },
        }),

        getConvoy: builder.query({
            query: (id) => ({
                url: `admin/service_convoys/${id}`,
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: 'Convoy', id }],
        }),

        updateConvoy: builder.mutation({
            query: ({ data, id }) => ({
                url: `admin/service_convoys/${id}/`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Convoy', id: arg.id }],
        }),

        updateConvoyStatus: builder.mutation({
            query: ({ data, id }) => ({
                url: `admin/service_convoys/${id}/status/`,
                method: 'PATCH',
                body: data,
            }),

            invalidatesTags: (result, error, arg) => [
                { type: 'Convoy', id: arg.id },
                { type: 'Convoy', id: 'LIST' },
                { type: 'ConvoyPlanning', id: 'LIST' },
            ],

            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled

                    notification.success({ message: 'Statut mis à jours avec succès', duration: 2 })
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
        }),

        updateConvoyResponsible: builder.mutation({
            query: ({ data, id }) => ({
                url: `admin/service_convoys_staff/${id}/`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Convoy', id: arg.id },
                { type: 'Convoy', id: 'LIST' },
                { type: 'ConvoyPlanning', id: 'LIST' },
            ],

            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled

                    notification.success({
                        message: 'Responsable mis à jours avec succès',
                        duration: 1,
                    })
                } catch (error) {
                    const { data } = error?.error

                    notification.error({
                        message: data?.funovia[0] || 'Une erreur est survenue',
                        duration: 2,
                    })

                    Sentry.captureException(error)
                }
            },
        }),

        getConvoyPlanning: builder.query({
            query: (cursorDate) => ({
                url: `admin/service_convoys_planing/`,
                method: 'GET',
                params: {
                    start: cursorDate.start,
                    end: cursorDate.end,
                },
            }),
        }),
        bookConvoy: builder.mutation({
            query: (body) => ({
                url: `admin/service_convoys/`,
                method: 'POST',
                body: body,
            }),
            async onQueryStarted(body, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled

                    dispatch(
                        setConvoyAddDataForm({
                            convoyId: data.id,
                            availability: data.availability,
                            funeral: data.funeral,
                            bury_date: data.bury_date,
                            bury_date_am_pm: data.bury_date_am_pm,
                        })
                    )
                } catch (err) {
                    Sentry.captureException(err)
                }
            },
        }),

        deleteConvoy: builder.mutation({
            query: (id) => ({
                url: `admin/service_convoys/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Convoy', id: arg.id },
                { type: 'Funeral', id: arg.id },
            ],
        }),

        deleteConvoyFile: builder.mutation({
            query: (id) => ({
                url: `admin/medias/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Convoy', id: arg.id },
                { type: 'Funeral', id: arg.id },
            ],
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetConvoysQuery,
    useCreateConvoyMutation,
    useGetConvoyQuery,
    useUpdateConvoyMutation,
    useUpdateConvoyStatusMutation,
    useUpdateConvoyResponsibleMutation,
    useBookConvoyMutation,
    useGetConvoyPlanningQuery,
    useDeleteConvoyMutation,
    useDeleteConvoyFileMutation,
} = embalmingApi
