import { adminApi } from '@redux/rtk/api'
import queryString from 'query-string'

import { setAgentsPagination, setAgentsTotalLineToPagination } from './agentSlice'
import { notification } from 'antd'
import * as Sentry from '@sentry/nextjs'

const agent = adminApi.injectEndpoints({
    endpoints: (builder) => ({
        getAgents: builder.query({
            query: (params) => ({
                url: `admin/agent_users/`,
                method: 'GET',
                params,
            }),

            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result?.results?.map(({ id }) => ({ type: 'Agent', id })),
                          { type: 'Agent', id: 'LIST' },
                      ]
                    : [{ type: 'Agent', id: 'LIST' }],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const result = await queryFulfilled

                    const { data } = result

                    const { page, size } = queryString.parseUrl(data.next).query

                    // const params = {
                    //     page: Number(page),
                    //     size: Number(size),
                    //     total: data?.count,
                    // }

                    // dispatch(setAgentsPagination(params))

                    dispatch(setAgentsTotalLineToPagination(data.count))
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
        }),

        getAgentsNoPage: builder.query({
            query: (params) => ({
                url: `admin/agent_users/`,
                method: 'GET',
                params: {
                    no_page: true,
                    ...(params || {}),
                },
            }),
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result?.map(({ id }) => ({ type: 'Agent', id })),
                          { type: 'Agent', id: 'LIST_NO_PAGE' },
                      ]
                    : [{ type: 'Agent', id: 'LIST_NO_PAGE' }],
        }),

        getAgent: builder.query({
            query: (id) => ({
                url: `admin/agent_users/${id}/`,
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: 'Agent', id }],
        }),

        createAgent: builder.mutation({
            query: (body) => ({
                url: `admin/agent_users/`,
                method: 'POST',
                body,
            }),
            invalidatesTags: [
                { type: 'Agent', id: 'LIST' },
                { type: 'Agent', id: 'LIST_NO_PAGE' },
            ],
        }),

        editAgent: builder.mutation({
            query: ({ data, id }) => ({
                url: `admin/agent_users/${id}/`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Agent', id: arg.id }],

            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled

                    const notificationOptions = {
                        message: 'Agent modifiée avec succès',
                        duration: 2,
                    }
                    notification.success(notificationOptions)
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
        }),

        deleteAgent: builder.mutation({
            query: (id) => ({
                url: `admin/agent_users/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: [
                { type: 'Agent', id: 'LIST' },
                { type: 'Agent', id: 'LIST_NO_PAGE' },
            ],
        }),

        deleteAgentAvatar: builder.mutation({
            query: (id) => ({
                url: `admin/agent_users/${id}/avatar/`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Agent', id: arg.id },
                { type: 'Me' },
            ],
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetAgentsQuery,
    useGetAgentsNoPageQuery,
    useGetAgentQuery,
    useCreateAgentMutation,
    useEditAgentMutation,
    useDeleteAgentMutation,
    useDeleteAgentAvatarMutation,
} = agent
