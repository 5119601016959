import { adminApi } from '@redux/rtk/api'
import { setCompaniesTotalLineToPagination } from './companySlice'
import { notification } from 'antd'
import * as Sentry from '@sentry/nextjs'

const companyApi = adminApi.injectEndpoints({
    endpoints: (builder) => ({
        getCompanies: builder.query({
            query: (params) => ({
                url: `admin/companies/`,
                method: 'GET',
                params,
            }),
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result?.results?.map(({ id }) => ({ type: 'Company', id })),
                          { type: 'Company', id: 'LIST' },
                      ]
                    : [{ type: 'Company', id: 'LIST' }],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const result = await queryFulfilled

                    const { data } = result

                    dispatch(setCompaniesTotalLineToPagination(data.count))
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
        }),

        getCompany: builder.query({
            query: (id) => ({
                url: `admin/companies/${id}/`,
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: 'Company', id }],
        }),
        editCompany: builder.mutation({
            query: ({ data, id }) => ({
                url: `admin/companies/${id}/`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Company', id: arg.id }],

            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled

                    const notificationOptions = {
                        message: 'Entreprise modifiée avec succès',
                        duration: 2,
                    }
                    notification.success(notificationOptions)
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
        }),
        createCompany: builder.mutation({
            query: (data) => ({
                url: `admin/companies/`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (result, error, body) => [{ type: 'Company', id: 'LIST' }],

            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                } catch (error) {
                    

                    const { data } = error.error

                    Object.values(data).forEach((error, i) => {
                        const notificationOptions = {
                            message: error[i],
                            duration: 2,
                        }
                        notification.error(notificationOptions)
                    })

                    Sentry.captureException(error)
                    // Sentry
                }
            },
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetCompaniesQuery,
    useGetCompanyQuery,
    useEditCompanyMutation,
    useCreateCompanyMutation,
} = companyApi
