import { FUNERAL_DECEASED_TITLE, HAVE_BURY_DATE, ORDERS_STATUSES } from '@common/constants'
import {
    removeListFieldInFilter,
    resetListActualFilter,
    setListActualFilter,
    setListPagination,
    setOptionsToAgencyField,
    setOptionsToAgentField,
    setTotalInPagination,
} from '@redux/common'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { addDisabledToFields } from '@helpers/permissions'

const initialState = {
    addFields: {
        death: [
            {
                name: 'deceased_title',
                label: 'Civilité',
                placeholder: 'Selectionner une civilité',
                options: FUNERAL_DECEASED_TITLE,
                component: 'select',
            },
            {
                name: 'deceased_firstname',
                label: 'Prénom',
                component: 'text',
            },
            {
                name: 'deceased_lastname',
                label: 'Nom',
                component: 'text',
            },
            {
                name: 'death_date',
                label: 'Date du décès',
                component: 'date',
                placeholder: 'Selectionner une date',
                format: 'DD/MM/YYYY',
            },
        ],
        agency: [
            {
                name: 'agency',
                label: 'Agence',
                placeholder: 'Selectionner une agence',
                component: 'select',
                options: [],
            },
        ],

        agent: [
            {
                name: 'agent',
                label: 'Conseiller',
                placeholder: 'Selectionner un conseiller',
                component: 'select',
                options: [],
            },
        ],
    },

    editFields: {
        requiredPermissions: [['core.Funeral', 'change_funeral']],

        death: [
            {
                name: 'deceased_title',
                label: 'Civilité',
                placeholder: 'Selectionner une civilité',
                options: FUNERAL_DECEASED_TITLE,
                component: 'select',
            },
            {
                name: 'deceased_firstname',
                label: 'Prénom',
                component: 'text',
            },
            {
                name: 'deceased_lastname',
                label: 'Nom',
                component: 'text',
            },
            {
                name: 'death_date',
                label: 'Date du décès',
                component: 'date',
                placeholder: 'Selectionner une date',
                format: 'DD/MM/YYYY',
            },
        ],
        deceased: [
            {
                name: 'bury_date',
                label: 'Date du décès',
                component: 'date',
                placeholder: 'Selectionner une date',
                format: 'DD/MM/YYYY',
                withStatus: false,
            },
            {
                name: 'bury_am_pm',
                label: 'Demi-journée',
                component: 'switch',
                checkedChildren: 'Matin',
                unCheckedChildren: 'Après-midi',
                withStatus: false,
            },
        ],
        agency: [
            {
                name: 'agency',
                label: 'Agence',
                placeholder: 'Selectionner une agence',
                component: 'select',
                options: [],
            },
        ],
        agent: [
            {
                name: 'agent',
                label: 'Conseiller',
                placeholder: 'Selectionner un conseiller',
                component: 'select',
                options: [],
            },
        ],
    },

    filters: {
        fields: {
            drawer: [
                {
                    name: 'bury_date',
                    component: 'dateRange',
                    label: 'Date de mise en bière',
                    labelCol: 24,
                    labelIcon: 'calendar-alt',
                    placeholder: ['Du', 'au'],
                    format: 'DD/MM/YYYY',
                    options: [],
                    withStatus: false,
                },
                {
                    name: 'have_bury_date',
                    component: 'radio',
                    options: HAVE_BURY_DATE,
                    withStatus: false,
                    checkedChildren: 'Planifié',
                    unCheckedChildren: 'Non planifié',
                },
                {
                    name: 'agency',
                    label: 'Agences',
                    labelCol: 24,
                    labelIcon: 'shop',
                    component: 'multiSelect',
                    placeholder: 'Selectionner des agences',
                    options: [],
                    withStatus: false,
                },
                {
                    name: 'agent',
                    label: 'Conseillers',
                    labelCol: 24,
                    labelIcon: 'users',
                    component: 'multiSelect',
                    placeholder: 'Selectionner des conseillers',
                    options: [],
                    withStatus: false,
                },
                {
                    name: 'service_status',
                    label: 'Statuts des services',
                    labelCol: 24,
                    labelIcon: 'cube',
                    component: 'multiSelect',
                    placeholder: 'Selectionner des status',
                    options: ORDERS_STATUSES,
                    withStatus: false,
                },
                {
                    name: 'total_gross',
                    label: 'Montant',
                    labelCol: 24,
                    labelIcon: 'coins',
                    component: 'slider',
                    withStatus: false,
                    range: [0, 10000],
                },
            ],
            fastSearch: [
                {
                    name: 'search',
                    placeholder: 'recherche rapide (défunt, ville, …)',
                    component: 'search',
                    withStatus: false,
                },
            ],
        },
        actual: {},
    },

    pagination: {
        page: 1,
        size: 15,
        total: 0,
    },
}

const funeralSlice = createSlice({
    name: 'funeral',
    initialState,
    reducers: {
        // Select options
        setAgentsOptions: setOptionsToAgentField,
        setAgenciesOptions: setOptionsToAgencyField,

        // Filters
        setFuneralsFilters: setListActualFilter,
        resetFuneralsFilters: resetListActualFilter,

        // Pagination
        setFuneralsPagination: setListPagination,
        setFuneralsTotalLineToPagination: setTotalInPagination,
    },
})

export const {
    // FORM OPTIONS
    setAgentsOptions,
    setAgenciesOptions,

    // FILTERS
    setFuneralsFilters,
    resetFuneralsFilters,

    // PAGINATION
    setFuneralsPagination,
    setFuneralsTotalLineToPagination,
} = funeralSlice.actions

export default funeralSlice.reducer

// Root selector
const funeralSelector = (state) => state.funeral
const authSelector = (state) => state.auth

export const selectFuneralsFieldsFilters = createSelector(
    funeralSelector,
    (funeralState) => funeralState.filters.fields
)

export const selectFuneralsFilters = createSelector(funeralSelector, (funeralState) => {
    return funeralState.filters.actual
})

export const selectFuneralsPagination = createSelector(
    funeralSelector,
    (funeralState) => funeralState.pagination
)

export const selectFuneralsPaginationParams = createSelector(funeralSelector, (funeralState) =>
    _.pick(funeralState.pagination, ['page', 'size'])
)

export const selectFuneralAddFields = createSelector(
    funeralSelector,
    (funeralState) => funeralState.addFields
)

export const selectFuneralEditFields = createSelector(
    funeralSelector,
    authSelector,
    (funeralState, authState) => {
        return addDisabledToFields(funeralState?.editFields, authState)
    }
)

export const selectFuneralsNumberOfActualFilters = createSelector(
    funeralSelector,
    (funeralState) => {
        const countWithoutOrderingAndSearch = _.omit(funeralState.filters?.actual, [
            'search',
            'ordering',
        ])

        // Get number of filter who's value is not empty or null and object filtered
        const countWithoutEmptyArrayOrNullishValue = Object.keys(
            _.omitBy(
                countWithoutOrderingAndSearch,
                (value) => value?.length === 0 || _.isNil(value)
            )
        ).length

        return countWithoutEmptyArrayOrNullishValue
    }
)

export const selectFirstAgencyInOptions = createSelector(
    funeralSelector,
    (funeralState) => funeralState.addFields.informations.agency[0].options[0]
)
