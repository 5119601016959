/* eslint-disable react-hooks/rules-of-hooks */
import { checkPermissions } from '@helpers/permissions'
import { useGetMeQuery } from '@redux/features/auth'
import { permissionsByRoute } from '@routes/index'
import { notification } from 'antd'
import _ from 'lodash'
import { useRouter } from 'next/router'
import React from 'react'
import { useSelector } from 'react-redux'

/**
 * Create a middleware to check if user can access to the page
 * with the permissions object in auth Me object
 */
const withPermission = (WrappedComponent) => {
    const RequiresPermission = (props) => {
        const { isAuth } = useSelector((state) => state.auth)

        const { isLoading, data } = useGetMeQuery({}, { skip: !isAuth, pollingInterval: 30000 })

        // Get the current route path
        const { pathname, push } = useRouter()

        const verifyPermissions = React.useCallback(
            (permissions) => {
                // Check if the current route is in permissionsByRoute
                if (!_.has(permissionsByRoute, pathname)) {
                    return false
                }

                // Get the permissions for the current route
                const permissionsRequired = permissionsByRoute[pathname]

                // Check if user has permissions
                let isAuthorized = checkPermissions(permissions, permissionsRequired)

                if (!isAuthorized) {
                    notification.error({
                        message: "Vous n'avez pas les permissions pour accéder à cette page",
                    })

                    // Redirect to home page
                    push({ pathname: '/' })
                }
                // eslint-disable-next-line react-hooks/exhaustive-deps
            },
            [pathname, push]
        )

        React.useEffect(() => {
            if (isLoading || !isAuth) return

            // Check if user has permissions only when data is fetching
            verifyPermissions(data?.permissions)
        }, [isLoading, data?.permissions, verifyPermissions])

        // Return the component if user has permissions and loading finished
        return !isLoading && <WrappedComponent {...props} />
    }

    return RequiresPermission
}

export default withPermission
