import { ANTD_CONFIG } from '@common/constants'
import Template from '@components/layout/Template'
import { PATHNAME_TO_DEFAULT_RESET_FIELDS } from '@constants'
import withAuthentication from '@middlewares/withAuthentication'
import withPermission from '@middlewares/withPermission'
// import withAuthentication from 'src/middlewares/withAuthentication'
// import withPermission from 'src/middlewares/withPermission'
import { resetAddressBook } from '@redux/features/addressBook'
import { resetConvoyAddDataForm } from '@redux/features/orders/convoys'
import { resetTransferAddDataForm } from '@redux/features/orders/transfers/transferSlice'
import { persistor, store } from '@redux/store'
import { ConfigProvider } from 'antd'
import { useRouter } from 'next/router'
import React from 'react'
import { Provider, useDispatch } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import '@styles/index.scss'
import { resetOrderOpened, setOrderOpened } from '@redux/features/drawer/drawerSlice'

const ProtectLogin = withAuthentication(({ children }) => {
    // We create a ProtectLogin component to wrap only here all pages else we need to set withAuthentication on each page

    const { events, pathname } = useRouter()

    const dispatch = useDispatch()

    // Handle router change and reset address book and convoy form because if we change page and come back to convoy form, we need to reset the form
    // When we using step the page don't change so we can't reset form inside the page
    // All reset in any page of customer app need to be set here
    React.useEffect(() => {
        const handleRouteChange = (url) => {
            if (PATHNAME_TO_DEFAULT_RESET_FIELDS.includes(pathname)) {
                dispatch(resetAddressBook())

                dispatch(resetConvoyAddDataForm())
            }

            // reset drawer on each page change
            dispatch(resetOrderOpened())
        }

        events.on('routeChangeComplete', handleRouteChange)

        return () => events.off('routeChangeComplete', handleRouteChange)
    }, [events, pathname])

    return children
})

const ProtectPermission = withPermission(({ children }) => {
    // We create a ProtectPermission component to wrap only here all pages else we need to set withPermission on each page
    return children
})

const FunoviaAdminWebApplication = ({ Component, pageProps }) => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ConfigProvider {...ANTD_CONFIG}>
                    <ProtectLogin>
                        <ProtectPermission>
                            <Template>
                                <Component {...pageProps} />
                            </Template>
                        </ProtectPermission>
                    </ProtectLogin>
                </ConfigProvider>
            </PersistGate>
        </Provider>
    )
}

export default FunoviaAdminWebApplication
