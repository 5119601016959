import { adminApi } from '@redux/rtk/api'
import { combineReducers } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import addressBookReducer from './features/addressBook/addressBookSlice'
import agencyReducer from './features/agencies/agencySlice'
import agentReducer from './features/agents/agentSlice'
import authReducer from './features/auth/authSlice'
import companyReducer from './features/companies/companySlice'
import drawerReducer from './features/drawer/drawerSlice'
import transferReducer from './features/orders/transfers/transferSlice'
import groupReducer from './features/personals/groups/groupSlice'
import funoviaUserReducer from './features/personals/users/funoviaUserSlice'
import embalmingReducer from './features/orders/embalmings/embalmingSlice'
import procedureReducer from './features/orders/procedures/procedureSlice'
import convoyReducer from './features/orders/convoys/convoySlice'
import funeralReducer from './features/funerals/funeralSlice'
import funeralPlanningReducer from './features/funeralPlanning/funeralPlanningSlice'
import convoyPlanningReducer from './features/convoysPlanning/convoyPlanningSlice'
import statisticalsReducer from './features/statisticals/statisticalsSlice'

// If you need to omit some nested data, you need to set the key associate in root persist config
// and set the same key in the nested reducer
// Auth is an good example
// If you need more exmaple: ref to: https://github.com/rt2zz/redux-persist/issues/1110#issuecomment-548408907

const rootPersistConfig = {
    key: 'root',
    storage: storage,
    blacklist: [
        'drawer',
        'auth',
        'company',
        'group',
        'funoviaUser',
        'agency',
        'agent',
        'transfer',
        'embalming',
        'procedure',
        'convoy',
        'funeral',
        'addressBook',
        'funeralPlanning',
        'convoyPlanning',
        'statisticals',
        adminApi.reducerPath,
    ],
}

const authPersistConfig = {
    key: 'auth', // This key must be the same inside root reducer for nested fied and exclude them
    storage: storage,
    blacklist: ['editFields', 'loginFields', 'permissions'],
}

const rootReducer = combineReducers({
    auth: persistReducer(authPersistConfig, authReducer),
    drawer: drawerReducer,
    group: groupReducer,
    agency: agencyReducer,
    funoviaUser: funoviaUserReducer,
    agent: agentReducer,
    transfer: transferReducer,
    embalming: embalmingReducer,
    procedure: procedureReducer,
    convoy: convoyReducer,
    funeral: funeralReducer,
    addressBook: addressBookReducer,
    funeralPlanning: funeralPlanningReducer,
    convoyPlanning: convoyPlanningReducer,
    statisticals: statisticalsReducer,
    company: companyReducer,

    [adminApi.reducerPath]: adminApi.reducer,
})

export const persistedRootReducer = persistReducer(rootPersistConfig, rootReducer)
