export const permissionsByRoute = {
    '/': ['all'],
    '/my-profil/edit': ['all'],
    '/404': ['all'],

    '/funerals': [
        ['core.Funeral', 'view_funeral'],
        ['core.Funeral', 'view_assigned_service__funeral'],
    ],
    '/funerals/archived': [
        ['core.Funeral', 'view_funeral'],
        ['core.Funeral', 'view_assigned_service__funeral'],
    ],
    '/funerals/planning': [
        ['core.Funeral', 'view_funeral'],
        ['core.Funeral', 'view_assigned_service__funeral'],
    ],
    '/funerals/create': [['core.Funeral', 'add_funeral']],
    '/funerals/[id]': [
        ['core.Funeral', 'change_funeral'],
        ['core.Funeral', 'view_funeral'],
        ['core.Funeral', 'view_assigned_service__funeral'],
    ],

    '/orders/transfers': [['core.ServiceTransfer', 'view_servicetransfer']],
    '/orders/transfers/create': [['core.ServiceTransfer', 'add_servicetransfer']],
    '/orders/transfers/[id]': [
        ['core.ServiceTransfer', 'change_servicetransfer'],
        ['core.ServiceTransfer', 'view_servicetransfer'],
    ],

    '/orders/convoys/planning': [
        ['core.ServiceConvoy', 'view_serviceconvoy'],
        ['core.ServiceConvoy', 'view_assigned_service_convoy'],
    ],

    '/orders/convoys': [
        ['core.ServiceConvoy', 'view_serviceconvoy'],
        ['core.ServiceConvoy', 'view_assigned_service_convoy'],
    ],
    '/orders/convoys/create': [['core.ServiceConvoy', 'add_serviceconvoy']],
    '/orders/convoys/[id]': [
        ['core.ServiceConvoy', 'change_serviceconvoy'],
        ['core.ServiceConvoy', 'view_serviceconvoy'],
        ['core.ServiceConvoy', 'view_assigned_service_convoy'],
    ],

    '/orders/embalmings': [['core.ServiceEmbalming', 'view_serviceembalming']],
    '/orders/embalmings/create': [['core.ServiceEmbalming', 'add_serviceembalming']],
    '/orders/embalmings/[id]': [
        ['core.ServiceEmbalming', 'change_serviceembalming'],
        ['core.ServiceEmbalming', 'view_serviceembalming'],
    ],

    '/orders/procedures': [['core.ServiceProcedure', 'view_serviceprocedure']],
    '/orders/procedures/create': [['core.ServiceProcedure', 'add_serviceprocedure']],
    '/orders/procedures/[id]': [
        ['core.ServiceProcedure', 'change_serviceprocedure'],
        ['core.ServiceProcedure', 'view_serviceprocedure'],
    ],

    '/team/availabilities': [['core.ServiceConvoyStaffAbsence', 'add_serviceconvoystaffabsence']],

    '/companies': [['core.Company', 'view_company']],
    '/companies/create': [['core.Company', 'add_company']],
    '/companies/[id]': [
        ['core.Company', 'change_company'],
        ['core.Company', 'view_company'],
    ],

    '/companies/agencies': [['core.Agency', 'view_agency']],
    '/companies/agencies/create': [['core.Agency', 'add_agency']],
    '/companies/agencies/[id]': [
        ['core.Agency', 'change_agency'],
        ['core.Agency', 'view_agency'],
    ],

    '/companies/agents': [['core.Agent', 'view_agent']],
    '/companies/agents/create': [['core.Agent', 'add_agent']],
    '/companies/agents/[id]': [
        ['core.Agent', 'change_agent'],
        ['core.Agent', 'view_agent'],
    ],

    '/personals/users': [['core.Funovia', 'view_funovia']],
    '/personals/users/create': [['core.Funovia', 'add_funovia']],
    '/personals/users/[id]': [
        ['core.Funovia', 'change_funovia'],
        ['core.Funovia', 'view_funovia'],
    ],

    '/personals/groups': [['auth.Group', 'view_group']],
    '/personals/groups/[id]': [
        ['auth.Group', 'change_group'],
        ['auth.Group', 'view_group'],
    ],

    '/auth/login': ['all'],
}
