import { createSelector, createSlice } from '@reduxjs/toolkit'

const initialState = {
    editFields: {
        informations: [
            {
                name: 'username',
                label: 'Identifiant',
                component: 'text',
                disabled: true,
            },
            {
                name: 'firstname',
                label: 'Prénom',
                component: 'text',
            },
            {
                name: 'lastname',
                label: 'Nom',
                component: 'text',
            },
            {
                name: 'email',
                label: 'email',
                component: 'text',
            },
            {
                name: ['funovia', 'phone'],
                label: 'Tel.',
                component: 'phone',
            },
        ],
        password: [
            {
                name: 'password',
                label: 'Nouveau ',
                component: 'password',
            },
            {
                name: 'password_confirm',
                label: 'Confirmer',
                component: 'password',
            },
        ],
    },

    loginFields: [
        {
            name: 'username',
            label: 'Identifiant',
            component: 'text',
        },
        {
            name: 'password',
            label: 'Mot de passe',
            component: 'password',
        },
    ],

    isAuth: false,
    permissions: [],
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setIsAuth: (state, action) => {
            state.isAuth = action.payload
        },
        setPermissions: (state, action) => {
            state.permissions = action.payload
        },
    },
})

export const { setIsAuth, setPermissions } = authSlice.actions
export default authSlice.reducer

// Root selector
export const authSelector = (state) => state.auth

export const selectAuthLoginFields = createSelector(
    authSelector,
    (authState) => authState.loginFields
)

export const selectAuthFieldsEdit = createSelector(
    authSelector,
    (authState) => authState.editFields
)

export const selectAuthIsAuth = createSelector(authSelector, (authState) => authState.isAuth)
