import { findBreadcrumbByPath } from '@common/utils/helpers'
import { useGetCompanyQuery } from '@redux/features/companies'
import { useGetFuneralQuery } from '@redux/features/funerals'
import { breadcrumbs as breadcrumbsList } from '@routes/index'
import { Breadcrumb } from 'antd'
import { isEmpty } from 'lodash'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

export default function Breadcrumbs() {
    const { pathname, query } = useRouter()

    const { data, isLoading } = useGetFuneralQuery(query?.id, {
        skip: !query?.id || pathname !== '/funerals/[id]',
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
    })

    const { formattedName, isLoadingCompany } = useGetCompanyQuery(query?.id, {
        skip: !query?.id || pathname !== '/companies/[id]',
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
        selectFromResult: ({ data }) => ({
            formattedName: `${data?.name} #${data?.id}`,
            isLoadingCompany: data?.isLoading,
        }),
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const breadcrumbs = React.useMemo(() => {
        const crumbs = findBreadcrumbByPath(pathname, breadcrumbsList)

        const items = []

        // Add crumbs with path finded
        crumbs.forEach((crumb) => {
            // If it's last we only show the label
            const isLastBreadcrumb = crumbs[crumbs.length - 1] === crumb

            items.push({
                component: (
                    <Breadcrumb.Item
                        key={crumb.path}
                        separator={<i className="fa-light fa-chevron-right" />}
                    >
                        {(isLastBreadcrumb &&
                            !['/funerals/[id]', '/companies/[id]'].includes(pathname)) ||
                        isEmpty(crumb.path) ? (
                            <span>{crumb.label}</span>
                        ) : (
                            <Link href={crumb.path}>{crumb.label}</Link>
                        )}
                    </Breadcrumb.Item>
                ),
            })
        })

        // If it's dynamic page create breadcrumb item and add it to the list
        switch (pathname) {
            case '/funerals/[id]':
                items.push({
                    component: (
                        <Breadcrumb.Item separator={<i className="fa-light fa-chevron-right" />}>
                            {data?.deceased_fullname} #{data?.id}
                        </Breadcrumb.Item>
                    ),
                })
                break
            case '/companies/[id]':
                items.push({
                    component: (
                        <Breadcrumb.Item separator={<i className="fa-light fa-chevron-right" />}>
                            {formattedName}
                        </Breadcrumb.Item>
                    ),
                })
                break
            default:
                break
        }

        return items
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, query?.id, isLoading, isLoadingCompany, formattedName])

    return (
        <Breadcrumb separator={<i className="fa-light fa-chevron-right " />}>
            <Breadcrumb.Item href="/" separator={<i className="fa-light fa-chevron-right" />}>
                <i className="fa-light fa-house" />
            </Breadcrumb.Item>

            {breadcrumbs?.map((breadcrumb, i) => (
                <React.Fragment key={i}>{breadcrumb?.component}</React.Fragment>
            ))}
        </Breadcrumb>
    )
}
