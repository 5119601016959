import Flex from '@common-components/blocs/Flex'
import TooltipUser from '@common-components/layout/user-badge/Tooltip'
import { POLLING_INTERVAL } from '@common/constants'
import { useGetMeQuery, useLogoutMutation } from '@redux/features/auth'
import { Button, Dropdown, notification, Space } from 'antd'
import { useRouter } from 'next/router'
import React from 'react'

const UserBadge = () => {
    const { push } = useRouter()

    const [logout, { isSuccess: isSuccessLogout, reset }] = useLogoutMutation()

    const { avatarSrc, fullName } = useGetMeQuery(
        {},
        {
            pollingInterval: POLLING_INTERVAL,
            selectFromResult: ({ data }) => ({
                avatarSrc: data?.agent?.avatar?.default ?? null,
                fullName: `${data?.firstname} ${data?.lastname}`,
            }),
        }
    )

    const dropdownItems = [
        {
            key: '2',
            label: (
                <Space onClick={() => logout()}>
                    <i className="fa-light fa-right-from-bracket" />
                    Déconnexion
                </Space>
            ),
        },
    ]

    const handleSuccess = React.useCallback(() => {
        const notificationOptions = {
            message: 'Déconnexion réussie',
            duration: 2,
            onClose: () => {
                push({ pathname: '/auth/login' })
                reset()
            },
            onClick: () => {
                push({ pathname: '/auth/login' })
                reset()
            },
        }
        notification.success(notificationOptions)
    }, [push, reset])

    // handle success embalming create
    React.useEffect(() => {
        if (isSuccessLogout) {
            handleSuccess()
        }
    }, [isSuccessLogout, handleSuccess])

    return (
        <Flex align="center">
            <TooltipUser
                avatarSrc={avatarSrc}
                onClick={() => push('/my-profil/edit')}
                fullName={fullName}
            />

            <Dropdown menu={{ items: dropdownItems }} trigger={['hover']}>
                <Button type="secondary" size="medium">
                    <i className="fa-light fa-angle-down" />
                </Button>
            </Dropdown>
        </Flex>
    )
}

export default UserBadge
