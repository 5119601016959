import { addDisabledToFields } from '@helpers/permissions'
import {
    resetListActualFilter,
    setListActualFilter,
    setListPagination,
    setOptionsToCompanyField,
    setTotalInPagination,
} from '@redux/common'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState = {
    addFields: {
        company: [
            {
                name: 'company',
                label: 'Entreprise',
                placeholder: 'Sélectionnez une entreprise',
                component: 'select',
                options: [],
                withStatus: false,
            },
        ],
        informations: [
            {
                name: 'name',
                label: 'Nom',
                placeholder: "Nom de l'agence",
                component: 'text',
            },
            {
                name: 'phone',
                label: 'Tél.',
                placeholder: "Numéro de l'agence",
                component: 'phone',
            },
            {
                name: 'email',
                label: 'Email',
                placeholder: "Email de l'agence",
                component: 'text',
            },
        ],

        address: [
            {
                name: 'address',
                component: 'address',
                nestedFields: ['address', 'address_2', 'zipcode', 'city'],
            },
        ],
        ordersAllowed: [
            {
                name: 'can_book_service_transfer',
                label: 'Transferts',
                component: 'boolean',
            },
            {
                name: 'can_book_service_procedure',
                label: 'Démarches',
                component: 'boolean',
            },
            {
                name: 'can_book_service_embalming',
                label: 'Thanatopraxie',
                component: 'boolean',
            },
            {
                name: 'can_book_service_convoy',
                label: 'Convoi',
                component: 'boolean',
            },
        ],
    },

    editFields: {
        requiredPermissions: [['core.Agency', 'change_agency']],

        informations: [
            {
                name: 'name',
                label: 'Nom',
                placeholder: "Nom de l'agence",
                component: 'text',
            },
            {
                name: 'phone',
                label: 'Tél.',
                placeholder: "Numéro de l'agence",
                component: 'phone',
            },
            {
                name: 'email',
                label: 'Email',
                placeholder: "Email de l'agence",
                component: 'text',
            },
        ],
        address: [
            {
                name: 'address',
                component: 'address',
                nestedFields: ['address', 'address_2', 'zipcode', 'city'],
            },
        ],
        ordersAllowed: [
            {
                name: 'can_book_service_transfer',
                label: 'Transferts',
                component: 'boolean',
            },
            {
                name: 'can_book_service_procedure',
                label: 'Démarches',
                component: 'boolean',
            },
            {
                name: 'can_book_service_embalming',
                label: 'Thanatopraxie',
                component: 'boolean',
            },
            {
                name: 'can_book_service_convoy',
                label: 'Convoi',
                component: 'boolean',
            },
        ],
    },

    filters: {
        fields: {
            fastSearch: [
                {
                    name: 'search',
                    placeholder: 'recherche rapide (Nom, adresse, ville, …)',
                    component: 'search',
                    withStatus: false,
                },
            ],
            drawer: [
                {
                    name: 'company',
                    labelCol: 24,
                    labelIcon: 'shop',
                    label: 'Entreprise',
                    placeholder: 'Sélectionnez une entreprise',
                    component: 'multiSelect',
                    options: [],
                    withStatus: false,
                },
            ],
        },
        actual: {},
    },

    pagination: {
        page: 1,
        size: 15,
        total: 0,
    },
}

const agencySlice = createSlice({
    name: 'agency',
    initialState,
    reducers: {
        // FILTERS
        setAgenciesFilters: setListActualFilter,
        resetAgenciesFilters: resetListActualFilter,

        // PAGINATION
        setAgenciesPagination: setListPagination,
        setAgenciesTotalLineToPagination: setTotalInPagination,

        // SET OPTIONS:
        setCompaniesOptions: setOptionsToCompanyField,
    },
})

export const {
    // FILTERS
    setAgenciesFilters,
    resetAgenciesFilters,

    // PAGINATION
    setAgenciesPagination,
    setAgenciesTotalLineToPagination,

    // SET OPTIONS:
    setCompaniesOptions,
} = agencySlice.actions
export default agencySlice.reducer

// Root select
const agencySelector = (state) => state.agency
const authSelector = (state) => state.auth

// FILTERS
export const selectAgenciesFieldsFilters = createSelector(
    agencySelector,
    (agencyState) => agencyState.filters.fields
)

export const selectAgenciesActualFilters = createSelector(
    agencySelector,
    (agencyState) => agencyState.filters.actual
)

export const selectAgenciesNumberOfActualFilters = createSelector(agencySelector, (agencyState) => {
    const countWithoutOrderingAndSearch = _.omit(agencyState.filters?.actual, [
        'search',
        'ordering',
    ])

    // Get number of filter who's value is not empty or null and object filtered
    const countWithoutEmptyArrayOrNullishValue = Object.keys(
        _.omitBy(countWithoutOrderingAndSearch, (value) => value?.length === 0 || _.isNil(value))
    ).length

    return countWithoutEmptyArrayOrNullishValue
})

// PAGINATION
export const selectAgenciesPagination = createSelector(
    agencySelector,
    (agencyState) => agencyState.pagination
)

export const selectAgenciesParamsPagination = createSelector(agencySelector, (agencyState) =>
    _.omit(agencyState.pagination, ['total'])
)

// CREATE
export const selectAgencyAddFields = createSelector(
    agencySelector,
    (agencyState) => agencyState.addFields
)

// EDIT
export const selectAgencyEditFields = createSelector(
    agencySelector,
    authSelector,
    (agencyState, authState) => {
        return addDisabledToFields(agencyState.editFields, authState)
    }
)
