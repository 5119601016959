import LogoLong from '@common/assets/funovia-long-white.png'
import LogoShort from '@common/assets/funovia-short-white.png'
import { ANTD_CONFIG } from '@common/constants'
import { checkPermissions } from '@helpers/permissions'
import { Footer, Header, LayoutDashboard as LayoutDashboardLib, Sidebar } from '@libs/ke-library'
import { allRoutes } from '@routes/index'
import { permissionsByRoute } from '@routes/index'
import { Spin } from 'antd'
import { useRouter } from 'next/router'
import React from 'react'
import { useSelector } from 'react-redux'

import Breadcrumbs from './Breadcrumbs'
import SearchGlobal from './SearchGlobal'
import UserBadge from './UserBadge'

const LayoutDashboard = ({ routes, children }) => {
    const router = useRouter()

    return (
        <LayoutDashboardLib
            sidebar={
                <Sidebar
                    logos={[LogoLong, LogoShort]}
                    collapseIconClose={<i className="fa-light fa-circle-play" />}
                    collapseIconOpen={<i className="fa-light fa-circle-play" />}
                    bottomItems={[]}
                />
            }
            header={
                <Header
                    left={<Breadcrumbs />}
                    right={
                        <>
                            <SearchGlobal />
                            <UserBadge />
                        </>
                    }
                />
            }
            footer={<Footer author="kienso.fr" />}
            routes={routes}
            router={router}
            themeCl={ANTD_CONFIG}
        >
            {children}
        </LayoutDashboardLib>
    )
}

const CustomerLayout = ({ children }) => {
    // Initialize routes object
    const [routes, setRoutes] = React.useState([])

    // Get user permissions
    const userPermissions = useSelector((state) => state.auth.permissions)

    /**
     * Function to loop on routes and remove elements that user has no permissions.
     */
    const loopOnRoutes = React.useCallback(
        (routes) => {
            let newListOfRoutes = []

            // Loop on routes
            routes.forEach((route) => {
                // Get route path of current route
                const routePath = route.path

                // Loop on children for current route. That will remove children that user has no permissions.
                if (route.children) {
                    route.children = loopOnRoutes(route.children)
                }

                // Check if user has permissions of current route. If route is '#', it means that user has no permissions on this route.
                let isAuthorized =
                    route.path != '#'
                        ? checkPermissions(userPermissions, permissionsByRoute[routePath])
                        : false

                // If route is not authorized and has children, change path to '#'
                if (isAuthorized === false && route?.children?.length > 0) {
                    route.path = '#'
                    isAuthorized = true
                }

                // If route is authorized, push it to newListOfRoutes
                if (isAuthorized) {
                    newListOfRoutes.push(route)
                }
            })

            return newListOfRoutes
        },
        [userPermissions]
    )

    React.useEffect(() => {
        // Check if the user permissions are not empty
        if (!_.isEmpty(userPermissions)) {
            // Call loopOnRoutes function with allRoutes to remove routes that user has no permissions
            setRoutes(loopOnRoutes(_.cloneDeep(allRoutes)))
        }
    }, [userPermissions, loopOnRoutes])

    return <LayoutDashboard routes={routes}>{children}</LayoutDashboard>
}

export default CustomerLayout
