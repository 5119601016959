export const breadcrumbs = {
    '/': [
        {
            label: 'Tableau de bord',
            path: '/',
        },
    ],
    '/funerals/planning': [
        {
            label: 'Obsèques',
            path: '/funerals',
        },
        {
            label: 'Planning',
            path: '/funerals',
        },
    ],
    '/funerals': [
        {
            label: 'Obsèques',
            path: '/funerals',
        },
        {
            label: 'Liste',
            path: '/funerals',
        },
    ],
    '/funerals/create': [
        {
            label: 'Obsèques',
            path: '/funerals',
        },
        {
            label: 'Création',
            path: '/funerals/create',
        },
    ],
    '/funerals/[id]': [
        {
            label: 'Obsèques',
            path: '/funerals',
        },
    ],
    '/funerals/archived': [
        {
            label: 'Obsèques',
            path: '/funerals',
        },
        {
            label: 'Terminées',
            path: '/funerals/archived',
        },
    ],
    '/orders/transfers': [
        {
            label: 'Activités',
            path: '',
        },
        {
            label: 'Transferts',
            path: '/orders/transfers',
        },
    ],
    '/orders/transfers/create': [
        {
            label: 'Activités',
            path: '',
        },
        {
            label: 'Transferts',
            path: '/orders/transfers',
        },
        {
            label: 'Création',
            path: '/orders/transfers/create',
        },
    ],
    '/orders/transfers/[id]': [
        {
            label: 'Activités',
            path: '',
        },
        {
            label: 'Transferts',
            path: '/orders/transfers',
        },
        {
            label: 'Edition',
            path: '',
        },
    ],
    '/orders/convoys': [
        {
            label: 'Activités',
            path: '',
        },
        {
            label: 'Convois',
            path: '/orders/convoys',
        },
        {
            label: 'Liste',
            path: '/orders/convoys',
        },
    ],
    '/orders/convoys/planning': [
        {
            label: 'Activités',
            path: '',
        },
        {
            label: 'Convois',
            path: '/orders/convoys',
        },
        {
            label: 'Planning',
            path: '/orders/convoys/planning',
        },
    ],
    '/orders/convoys/create': [
        {
            label: 'Activités',
            path: '',
        },
        {
            label: 'Convois',
            path: '/orders/convoys',
        },
        {
            label: 'Création',
            path: '/orders/convoys/create',
        },
    ],
    '/orders/convoys/[id]': [
        {
            label: 'Activités',
            path: '',
        },
        {
            label: 'Convois',
            path: '/orders/convoys',
        },
        {
            label: 'Edition',
            path: '',
        },
    ],
    '/orders/embalmings': [
        {
            label: 'Activités',
            path: '',
        },
        {
            label: 'Thanatopraxies',
            path: '/orders/embalmings',
        },
    ],
    '/orders/embalmings/create': [
        {
            label: 'Activités',
            path: '',
        },
        {
            label: 'Thanatopraxies',
            path: '/orders/embalmings',
        },
        {
            label: 'Création',
            path: '/orders/embalmings/create',
        },
    ],
    '/orders/embalmings/[id]': [
        {
            label: 'Activités',
            path: '',
        },
        {
            label: 'Thanatopraxies',
            path: '/orders/embalmings',
        },
        {
            label: 'Edition',
            path: '',
        },
    ],
    '/orders/procedures': [
        {
            label: 'Activités',
            path: '',
        },
        {
            label: 'Démarches',
            path: '/orders/procedures',
        },
    ],
    '/orders/procedures/create': [
        {
            label: 'Activités',
            path: '',
        },
        {
            label: 'Démarches',
            path: '/orders/procedures',
        },
        {
            label: 'Création',
            path: '/orders/procedures',
        },
    ],
    '/orders/procedures/[id]': [
        {
            label: 'Activités',
            path: '',
        },
        {
            label: 'Démarches',
            path: '/orders/transfers',
        },
        {
            label: 'Edition',
            path: '',
        },
    ],
    '/team/convoys': [
        {
            label: 'Equipes',
            path: '/',
        },
    ],
    '/team/availabilities': [
        {
            label: 'Equipes',
            path: '',
        },
        {
            label: 'Disponibilités',
            path: '/team/availabilities',
        },
    ],

    '/companies': [
        {
            label: 'Entreprises',
            path: '/companies',
        },
    ],
    '/companies/create': [
        {
            label: 'Entreprises',
            path: '/companies',
        },
        {
            label: 'Création',
            path: '/companies/create',
        },
    ],
    '/companies/[id]': [
        {
            label: 'Entreprises',
            path: '/companies',
        },
    ],

    '/companies/agencies': [
        {
            label: 'Entreprises',
            path: '/companies',
        },
        {
            label: 'Agences',
            path: '/companies/agencies',
        },
    ],
    '/companies/agencies/create': [
        {
            label: 'Entreprises',
            path: '/companies',
        },
        {
            label: 'Agences',
            path: '/companies/agencies',
        },
        {
            label: 'Création',
            path: '/companies/agencies/create',
        },
    ],
    '/companies/agencies/[id]': [
        {
            label: 'Entreprises',
            path: '/companies',
        },
        {
            label: 'Agences',
            path: '/companies/agencies',
        },
        {
            label: 'Edition',
            path: '',
        },
    ],
    '/companies/agents': [
        {
            label: 'Entreprises',
            path: '/companies',
        },
        {
            label: 'Conseillers',
            path: '/companies/agents',
        },
    ],
    '/companies/agents/create': [
        {
            label: 'Entreprises',
            path: '/companies/',
        },
        {
            label: 'Conseillers',
            path: '/companies/agents',
        },
        {
            label: 'Création',
            path: '/companies/agents/create',
        },
    ],
    '/companies/agents/[id]': [
        {
            label: 'Entreprises',
            path: '/companies/',
        },
        {
            label: 'Conseillers',
            path: '/companies/agents',
        },
        {
            label: 'Edition',
            path: '',
        },
    ],

    '/personals/users': [
        {
            label: 'Personnels',
            path: '/personals/users',
        },
    ],
    '/personals/users/[id]': [
        {
            label: 'Personnels',
            path: '/personals/users',
        },
        {
            label: 'Edition',
            path: '',
        },
    ],
    '/personals/users/create': [
        {
            label: 'Personnels',
            path: '/personals/users',
        },
        {
            label: 'Création',
            path: '/personals/users/create',
        },
    ],

    '/personals/groups': [
        {
            label: 'Groupe de personnels',
            path: '/personals/groups',
        },
    ],
    '/personals/groups/[id]': [
        {
            label: 'Groupe de personnels',
            path: '/personals/groups',
        },
        {
            label: 'Edition',
            path: '/personals/groups/[id]',
        },
    ],

    '/my-profil/edit': [
        {
            label: 'Mon profil',
            path: '/my-business/edit',
        },
    ],
}
