import { adminApi } from '@redux/rtk/api'
import { setFunoviaUsersTotalLineToPagination } from './funoviaUserSlice'

const apiRoutePrefix = 'admin/funovia_users/'
import * as Sentry from '@sentry/nextjs'

const funoviaUserApi = adminApi.injectEndpoints({
    endpoints: (builder) => ({
        getFunoviaUsers: builder.query({
            query: (params) => ({
                url: `${apiRoutePrefix}`,
                method: 'GET',
                params,
            }),
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result?.results?.map(({ id }) => ({ type: 'FunoviaUser', id })),
                          { type: 'FunoviaUser', id: 'LIST' },
                      ]
                    : [{ type: 'FunoviaUser', id: 'LIST' }],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const result = await queryFulfilled

                    const { data } = result

                    dispatch(setFunoviaUsersTotalLineToPagination(data.count))
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
        }),
        getFunoviaUsersNoPage: builder.query({
            query: (params) => ({
                url: `${apiRoutePrefix}`,
                method: 'GET',
                params: {
                    no_page: true,
                    ...(params || {}),
                },
            }),
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result?.map(({ id }) => ({ type: 'FunoviaUser', id })),
                          { type: 'FunoviaUser', id: 'LIST_NO_PAGE' },
                      ]
                    : [{ type: 'FunoviaUser', id: 'LIST_NO_PAGE' }],
        }),
        getFunoviaUser: builder.query({
            query: (id) => ({
                url: `${apiRoutePrefix}${id}/`,
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: 'FunoviaUser', id }],
        }),
        editFunoviaUser: builder.mutation({
            query: ({ data, id }) => ({
                url: `${apiRoutePrefix}${id}/`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: (result, error, body) => [
                { type: 'FunoviaUser', id: body.id },
                { type: 'FunoviaUser', id: 'LIST' },
                { type: 'FunoviaUser', id: 'LIST_NO_PAGE' },
            ],
        }),
        createFunoviaUser: builder.mutation({
            query: (data) => ({
                url: `${apiRoutePrefix}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (result, error, body) => [
                { type: 'FunoviaUser', id: 'LIST' },
                { type: 'FunoviaUser', id: 'LIST_NO_PAGE' },
            ],
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetFunoviaUsersQuery,
    useGetFunoviaUsersNoPageQuery,
    useGetFunoviaUserQuery,
    useEditFunoviaUserMutation,
    useCreateFunoviaUserMutation,
} = funoviaUserApi
