import { IS_SUPERVISOR } from '@common/constants'
import { addDisabledToFields } from '@helpers/permissions'
import {
    resetListActualFilter,
    setListActualFilter,
    setListPagination,
    setOptionsToAgencyField,
    setOptionsToCompanyField,
    setTotalInPagination,
} from '@redux/common'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState = {
    addFields: {
        agent: [
            {
                name: 'username',
                label: 'Identifiant',
                placeholder: 'Mdoe',
                component: 'text',
            },
            {
                name: 'firstname',
                label: 'Prénom',
                placeholder: 'Mark',
                component: 'text',
            },
            {
                name: 'lastname',
                label: 'Nom',
                placeholder: 'Doe',
                component: 'text',
            },
            {
                name: 'email',
                label: 'Email',
                placeholder: 'mark@monagence.com',
                component: 'text',
            },
            {
                name: ['agent', 'phone'],
                label: 'Tél.',
                placeholder: '01 02 03 04 05',
                component: 'phone',
            },
        ],
        company: [
            {
                name: ['agent', 'company'],
                label: 'Entreprise',
                placeholder: 'Sélectionnez une entreprise',
                component: 'select',
                options: [],
                privateKey: 'company', // Used for add element inside options with easy way
            },
        ],
        notification: [
            {
                name: ['agent', 'allow_mail_notifications'],
                label: 'Suivi des obsèques par email',
                component: 'boolean',
            },
        ],
        avatar: [
            {
                name: ['agent', 'avatar'],
                component: 'file',
                acceptedTypes: ['image/png', 'image/jpeg', 'image/jpg', 'image/webm'],
                maxSize: 4,
                multiple: false,
            },
        ],
        permissions: [
            {
                name: ['agent', 'is_supervisor'],
                label: 'Superviseur',
                component: 'boolean',
            },
            {
                name: ['agent', 'can_loyalty_program'],
                label: 'Programme de fidélité',
                component: 'boolean',
            },
            {
                name: ['agent', 'can_see_prices'],
                label: 'Visualisation des prix',
                component: 'boolean',
            },
        ],
    },

    editFields: {
        requiredPermissions: [['core.Agent', 'change_agent']],

        agent: [
            {
                name: 'username',
                label: 'Identifiant',
                placeholder: 'Mdoe',
                component: 'text',
                disabled: true,
            },
            {
                name: 'firstname',
                label: 'Prénom',
                placeholder: 'Mark',
                component: 'text',
            },
            {
                name: 'lastname',
                label: 'Nom',
                placeholder: 'Doe',
                component: 'text',
            },
            {
                name: 'email',
                label: 'Email',
                placeholder: 'mark@monagence.com',
                component: 'text',
            },
            {
                name: ['agent', 'phone'],
                label: 'Tél.',
                placeholder: '01 02 03 04 05',
                component: 'phone',
            },
        ],
        notification: [
            {
                name: ['agent', 'allow_mail_notifications'],
                label: 'Suivi des obsèques par email',
                component: 'boolean',
            },
        ],
        avatar: [
            {
                name: ['agent', 'avatar'],
                component: 'file',
                acceptedTypes: ['image/png', 'image/jpeg', 'image/jpg', 'image/webm'],
                maxSize: 4,
                multiple: false,
            },
        ],
        permissions: [
            {
                name: ['agent', 'can_loyalty_program'],
                label: 'Programme de fidélité',
                component: 'boolean',
            },
            {
                name: ['agent', 'can_see_prices'],
                label: 'Visualisation des prix',
                component: 'boolean',
            },
        ],
    },

    filters: {
        fields: {
            fastSearch: [
                {
                    name: 'search',
                    placeholder: 'recherche rapide (Nom, adresse, ville, …)',
                    component: 'search',
                    withStatus: false,
                },
            ],
            drawer: [
                {
                    name: 'company',
                    labelCol: 24,
                    labelIcon: 'shop',
                    label: 'Entreprise',
                    placeholder: 'Sélectionnez des entreprises',
                    component: 'multiSelect',
                    options: [],
                    withStatus: false,
                },
                {
                    name: 'agency',
                    labelCol: 24,
                    labelIcon: 'shop',
                    label: 'Agence',
                    placeholder: 'Sélectionnez des agences',
                    component: 'multiSelect',
                    options: [],
                    withStatus: false,
                },
                {
                    name: 'is_supervisor',
                    labelCol: 24,
                    labelIcon: 'shop',
                    label: 'Superviseur',
                    component: 'radio',
                    options: IS_SUPERVISOR,
                },
            ],
        },
        actual: {},
    },

    pagination: {
        page: 1,
        size: 15,
        total: 0,
    },
}

const agentSlice = createSlice({
    name: 'agent',
    initialState,
    reducers: {
        // FILTERS
        setAgentsFilters: setListActualFilter,
        resetAgentsFilters: resetListActualFilter,

        // PAGINATION
        setAgentsPagination: setListPagination,
        setAgentsTotalLineToPagination: setTotalInPagination,

        // SET OPTIONS:
        setCompaniesOptions: setOptionsToCompanyField,
        setAgenciesOptions: setOptionsToAgencyField,
    },
})

export const {
    // FILTERS
    setAgentsFilters,
    resetAgentsFilters,

    // PAGINATION
    setAgentsPagination,
    setAgentsTotalLineToPagination,

    // SET OPTIONS:
    setCompaniesOptions,
    setAgenciesOptions,
} = agentSlice.actions
export default agentSlice.reducer

// Root select
const agentSelector = (state) => state.agent
const authSelector = (state) => state.auth

// FILTERS
export const selectAgentsFieldsFilters = createSelector(
    agentSelector,
    (agentState) => agentState.filters.fields
)

export const selectAgentsActualFilters = createSelector(
    agentSelector,
    (agentState) => agentState.filters.actual
)

export const selectAgentsNumberOfActualFilters = createSelector(agentSelector, (agentState) => {
    const countWithoutOrderingAndSearch = _.omit(agentState.filters?.actual, ['search', 'ordering'])

    // Get number of filter who's value is not empty or null and object filtered
    const countWithoutEmptyArrayOrNullishValue = Object.keys(
        _.omitBy(countWithoutOrderingAndSearch, (value) => value?.length === 0 || _.isNil(value))
    ).length

    return countWithoutEmptyArrayOrNullishValue
})

// PAGINATION
export const selectAgentsPagination = createSelector(
    agentSelector,
    (agentState) => agentState.pagination
)

export const selectAgentsParamsPagination = createSelector(agentSelector, (agentState) =>
    _.omit(agentState.pagination, ['total'])
)

// ADD FIELDS
export const selectAgentsAddFields = createSelector(
    agentSelector,
    (agentState) => agentState.addFields
)

// EDIT FIELDS
export const selectAgentsEditFields = createSelector(
    agentSelector,
    authSelector,
    (agentState, authState) => addDisabledToFields(agentState.editFields, authState)
)
