import { ADMISSION_TO_BE_SIGNED, ORDERS_STATUSES } from '@common/constants'
import {
    resetAddDataOrderForm,
    resetListActualFilter,
    setAddDataOrderForm,
    setListActualFilter,
    setListPagination,
    setOptionsToAgencyField,
    setOptionsToCompanyField,
    setOptionsToResponsibleField,
    setTotalInPagination,
} from '@redux/common'
import { createSelector, createSlice } from '@reduxjs/toolkit'

const initialState = {
    addData: null,
    addFields: {
        start: [
            {
                name: 'start_at',
                label: 'Date et heure',
                component: 'datetime',
                placeholder: 'Selectionner une date',
                format: 'DD/MM/YYYY HH:mm',
            },
        ],

        addition_information: [
            {
                name: 'additional_information',
                label: 'Informations complémentaires',
                component: 'textaera',
                rows: 2,
                labelCol: 24,
            },
        ],

        contact: [
            {
                name: 'contact_firstname',
                label: 'Prénom',
                component: 'text',
                placeholder: 'John',
            },
            {
                name: 'contact_lastname',
                label: 'Nom',
                placeholder: 'Doe',
                component: 'text',
            },
            {
                name: 'contact_phone',
                label: 'Tél.',
                component: 'phone',
            },
            {
                name: 'admission_to_be_signed',
                label: 'Demande d’admission à signer',
                component: 'radio',
                options: ADMISSION_TO_BE_SIGNED,
                labelCol: 24,
            },
        ],
        files: [
            {
                name: 'medias',
                component: 'file',
                maxSize: 4,
                acceptedTypes: [
                    'image/png',
                    'image/jpeg',
                    'image/jpg',
                    'image/webm',
                    'application/pdf',
                ],
                multiple: true,
            },
        ],
    },

    editFields: {
        start: [
            {
                name: 'start_at',
                label: 'Date et heure',
                component: 'datetime',
                placeholder: 'Selectionner une date',
                format: 'DD/MM/YYYY HH:mm',
            },
        ],
        addition_information: [
            {
                name: 'additional_information',
                label: 'Informations complémentaires',
                component: 'textaera',
                rows: 2,
                labelCol: 24,
            },
        ],
        contact: [
            {
                name: 'contact_firstname',
                label: 'Prénom',
                component: 'text',
                placeholder: 'John',
            },
            {
                name: 'contact_lastname',
                label: 'Nom',
                placeholder: 'Doe',
                component: 'text',
            },
            {
                name: 'contact_phone',
                label: 'Tél.',
                component: 'phone',
            },
            {
                name: 'admission_to_be_signed',
                label: 'Demande d’admission à signer',
                component: 'radio',
                options: ADMISSION_TO_BE_SIGNED,
                labelCol: 24,
            },
        ],

        files: [
            {
                name: 'medias',
                component: 'file',
                maxSize: 4,
                acceptedTypes: [
                    'image/png',
                    'image/jpeg',
                    'image/jpg',
                    'image/webm',
                    'application/pdf',
                ],
                multiple: true,
            },
        ],
    },

    filters: {
        fields: {
            fastSearch: [
                {
                    name: 'search',
                    placeholder: 'recherche rapide (Défunt, responsable, …)',
                    component: 'search',
                    withStatus: false,
                },
            ],
            drawer: [
                {
                    name: 'start_at',
                    component: 'dateRange',
                    label: 'Date du transfert',
                    labelCol: 24,
                    labelIcon: 'calendar-alt',
                    placeholder: ['Du', 'au'],
                    format: 'DD/MM/YYYY',
                    withStatus: false,
                },
                {
                    name: 'service_status',
                    label: 'Statuts des services',
                    labelCol: 24,
                    labelIcon: 'cube',
                    component: 'multiSelect',
                    placeholder: 'Selectionner des status',
                    options: ORDERS_STATUSES,
                    withStatus: false,
                },
                {
                    name: 'agency',
                    labelCol: 24,
                    labelIcon: 'shop',
                    label: 'Agences',
                    placeholder: 'Sélectionnez des agences',
                    component: 'multiSelect',
                    options: [],
                    withStatus: false,
                },
                {
                    name: 'responsible',
                    labelCol: 24,
                    labelIcon: 'shop',
                    label: 'Responsables',
                    placeholder: 'Sélectionnez des responsables',
                    component: 'multiSelect',
                    options: [],
                    withStatus: false,
                },
            ],
        },
        actual: {},
    },

    pagination: {
        page: 1,
        size: 15,
        total: 0,
    },
}

const transferSlice = createSlice({
    name: 'transfer',
    initialState,
    reducers: {
        // FILTERS
        setTransfersFilters: setListActualFilter,
        resetTransfersFilters: resetListActualFilter,

        // PAGINATION
        setTransfersPagination: setListPagination,
        setTransfersTotalLineToPagination: setTotalInPagination,

        // FORM
        setTransferAddDataForm: setAddDataOrderForm,
        resetTransferAddDataForm: resetAddDataOrderForm,

        // SET OPTIONS:
        setAgenciesOptions: setOptionsToAgencyField,
        setResponsiblesOptions: setOptionsToResponsibleField,
    },
})

export const {
    // FILTERS
    setTransfersFilters,
    resetTransfersFilters,

    // PAGINATION
    setTransfersPagination,
    setTransfersTotalLineToPagination,

    // FORM
    setTransferAddDataForm,
    resetTransferAddDataForm,

    // SET OPTIONS:
    setAgenciesOptions,
    setResponsiblesOptions,
} = transferSlice.actions
export default transferSlice.reducer

// Root selector
const transferSelector = (state) => state.transfer

// FILTERS
export const selectTransfersFieldsFilters = createSelector(
    transferSelector,
    (transferState) => transferState.filters.fields
)

export const selectTransfersActualFilters = createSelector(
    transferSelector,
    (transferState) => transferState.filters.actual
)

export const selectTransfersNumberOfActualFilters = createSelector(
    transferSelector,
    (transferState) => {
        const countWithoutOrderingAndSearch = _.omit(transferState.filters?.actual, [
            'search',
            'ordering',
        ])

        // Get number of filter who's value is not empty or null and object filtered
        const countWithoutEmptyArrayOrNullishValue = Object.keys(
            _.omitBy(
                countWithoutOrderingAndSearch,
                (value) => value?.length === 0 || _.isNil(value)
            )
        ).length

        return countWithoutEmptyArrayOrNullishValue
    }
)

// PAGINATION
export const selectTransfersPagination = createSelector(
    transferSelector,
    (transferState) => transferState.pagination
)

export const selectTransfersParamsPagination = createSelector(transferSelector, (transferState) =>
    _.omit(transferState.pagination, ['total'])
)

// CREATE
export const selectTransfersFieldsAdd = createSelector(
    transferSelector,
    (transferState) => transferState.addFields
)

export const selectTransfersAddData = createSelector(
    transferSelector,
    (transferState) => transferState.addData
)

// EDIT
export const selectTransfersFieldsEdit = createSelector(
    transferSelector,
    (transferState) => transferState.editFields
)
