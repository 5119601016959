import { adminApi } from '@redux/rtk/api'

import { setAgenciesTotalLineToPagination, setCompaniesTotalLineToPagination } from './agencySlice'
import { notification } from 'antd'
import * as Sentry from '@sentry/nextjs'

const agencyApi = adminApi.injectEndpoints({
    endpoints: (builder) => ({
        getAgencies: builder.query({
            query: (params) => ({
                url: `admin/agencies/`,
                method: 'GET',
                params,
            }),

            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result?.results?.map(({ id }) => ({ type: 'Agency', id })),
                          { type: 'Agency', id: 'LIST' },
                      ]
                    : [{ type: 'Agency', id: 'LIST' }],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const result = await queryFulfilled

                    const { data } = result

                    dispatch(setAgenciesTotalLineToPagination(data.count))
                } catch (error) {
                    Sentry.captureException(error)
                    
                }
            },
        }),

        getAgenciesNoPage: builder.query({
            query: (params) => ({
                url: `admin/agencies/`,
                method: 'GET',
                params: {
                    no_page: true,
                    ...(params || {}),
                },
            }),
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result?.map(({ id }) => ({ type: 'Agency', id })),
                          { type: 'Agency', id: 'LIST_NO_PAGE' },
                      ]
                    : [{ type: 'Agency', id: 'LIST_NO_PAGE' }],
        }),

        getAgency: builder.query({
            query: (id) => ({
                url: `admin/agencies/${id}/`,
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: 'Agency', id }],
        }),

        editAgency: builder.mutation({
            query: ({ data, id }) => ({
                url: `admin/agencies/${id}/`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: (result, error, { id }) => [
                { type: 'Agency', id },
                { type: 'Agent', id: 'LIST_NO_PAGE' },
            ],

            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled

                    const notificationOptions = {
                        message: 'Agence modifiée avec succès',
                        duration: 2,
                    }
                    notification.success(notificationOptions)
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
        }),
        createAgency: builder.mutation({
            query: (data) => ({
                url: `admin/agencies/`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (result, error, body) => [
                { type: 'Agency', id: 'LIST' },
                { type: 'Agent', id: 'LIST_NO_PAGE' },
            ],
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetAgenciesQuery,
    useGetAgenciesNoPageQuery,
    useGetAgencyQuery,
    useEditAgencyMutation,
    useCreateAgencyMutation,
} = agencyApi
