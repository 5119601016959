import Template from '@components/layout/Template'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const withAuthentication = (WrappedComponent) => {
    const RequiresAuthentication = (props) => {
        const router = useRouter()

        const { isAuth } = useSelector((state) => state.auth)

        // Set local state else component will be fire for 1 minimum time before redirect
        const [authChecked, setAuthChecked] = useState(false)

        useEffect(() => {
            // Prevent if user reload the login page for not be blocked to loading...
            if (router.pathname === '/auth/login' && router.isReady) {
                setAuthChecked(true)
            }

            // When router change is completed allow to render the component
            const handleRouteChangeComplete = (url) => {
                setAuthChecked(true)
            }

            // If user is not authenticated redirect to login page
            if (!isAuth) {
                if (router.pathname !== '/auth/login') {
                    router.push('/auth/login')
                }
            } else {
                setAuthChecked(true)

                // If user is authenticated redirect to home page
                if (router.pathname === '/auth/login') {
                    router.push('/')
                }
            }

            router.events.on('routeChangeComplete', handleRouteChangeComplete)

            return () => {
                router.events.off('routeChangeComplete', handleRouteChangeComplete)
            }
        }, [isAuth, router])

        // Show a spinning icon while checking authentication
        if (!authChecked) {
            return <div>Loading...</div>
        }

        // --------------------
        // At this time redux store is loaded the local state is finished so we can display component
        // --------------------

        return <WrappedComponent {...props} isAuth={isAuth} />
    }

    return RequiresAuthentication
}

export default withAuthentication
