import { resetTransferAddDataForm, setTransfersTotalLineToPagination } from './transferSlice'
import { resetAddressBook } from '@redux/features/addressBook'
import { adminApi } from '@redux/rtk/api'
import { notification } from 'antd'
import * as Sentry from '@sentry/nextjs'

const transfersApi = adminApi.injectEndpoints({
    endpoints: (builder) => ({
        getTransfers: builder.query({
            query: (params) => ({
                url: `admin/service_transfers/`,
                method: 'GET',
                params,
            }),
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result?.results?.map(({ id }) => ({ type: 'Transfer', id })),
                          { type: 'Transfer', id: 'LIST' },
                      ]
                    : [{ type: 'Transfer', id: 'LIST' }],

            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const result = await queryFulfilled

                    const { data } = result

                    dispatch(setTransfersTotalLineToPagination(data.count))
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
        }),

        getTransfer: builder.query({
            query: (id) => ({
                url: `admin/service_transfers/${id}`,
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: 'Transfer', id }],
        }),

        createTransfer: builder.mutation({
            query: (procedure) => ({
                url: `admin/service_transfers/`,
                method: 'POST',
                body: procedure,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Funeral' }],

            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled

                    dispatch(resetAddressBook())
                    dispatch(resetTransferAddDataForm())
                } catch (err) {}
            },
        }),

        updateTransfer: builder.mutation({
            query: ({ data, id }) => ({
                url: `admin/service_transfers/${id}/`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: (result, error, arg) => {
                return [{ type: 'Transfer', id: arg.id }]
            },

            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled

                    const notificationOptions = {
                        message: 'Transfert modifiée avec succès',
                        duration: 2,
                    }
                    notification.success(notificationOptions)
                } catch (err) {}
            },
        }),

        updateTransferStatus: builder.mutation({
            query: ({ data, id }) => ({
                url: `admin/service_transfers/${id}/status/`,
                method: 'PATCH',
                body: data,
            }),

            invalidatesTags: (result, error, arg) => [
                { type: 'Transfer', id: arg.id },
                { type: 'Transfer', id: 'LIST' },
            ],

            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled

                    notification.success({ message: 'Statut mis à jours avec succès', duration: 2 })
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
        }),

        updateTransferResponsible: builder.mutation({
            query: ({ data, id }) => ({
                url: `admin/service_transfers/${id}/responsible/`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Transfer', id: arg.id },
                { type: 'Transfer', id: 'LIST' },
            ],

            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled

                    notification.success({
                        message: 'Responsable mis à jours avec succès',
                        duration: 2,
                    })
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
        }),

        deleteTransfer: builder.mutation({
            query: (id) => ({
                url: `admin/service_transfers/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Funeral', id: arg.id },
                { type: 'Transfer', id: arg.id },
            ],

            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled

                    notification.success({
                        message: 'Service transfert supprimé',
                        duration: 1,
                    })
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
        }),

        deleteTransfersFile: builder.mutation({
            query: (id) => ({
                url: `admin/medias/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Funeral', id: arg.id },
                { type: 'Transfer', id: arg.id },
            ],
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetTransfersQuery,
    useGetTransferQuery,
    useCreateTransferMutation,
    useUpdateTransferMutation,
    useUpdateTransferResponsibleMutation,
    useUpdateTransferStatusMutation,
    useDeleteTransferMutation,
    useDeleteTransfersFileMutation,
} = transfersApi
