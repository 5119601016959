import { HAVE_BURY_DATE, ORDERS_STATUSES } from '@common/constants'
import {
    resetListActualFilter,
    setListActualFilter,
    setOptionsToAgencyField,
    setOptionsToAgentField,
} from '@redux/common'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState = {
    filters: {
        fields: {
            drawer: [
                {
                    name: 'bury_date',
                    component: 'dateRange',
                    label: 'Date de mise en bière',
                    labelCol: 24,
                    labelIcon: 'calendar-alt',
                    placeholder: ['Du', 'au'],
                    format: 'DD/MM/YYYY',
                    options: [],
                    withStatus: false,
                },
                {
                    name: 'have_bury_date',
                    component: 'radio',
                    options: HAVE_BURY_DATE,
                    withStatus: false,
                    checkedChildren: 'Planifié',
                    unCheckedChildren: 'Non planifié',
                },
                {
                    name: 'agency',
                    label: 'Agences',
                    labelCol: 24,
                    labelIcon: 'shop',
                    component: 'multiSelect',
                    placeholder: 'Selectionner des agences',
                    options: [],
                    withStatus: false,
                },
                {
                    name: 'agent',
                    label: 'Conseillers',
                    labelCol: 24,
                    labelIcon: 'users',
                    component: 'multiSelect',
                    placeholder: 'Selectionner des conseillers',
                    options: [],
                    withStatus: false,
                },
                {
                    name: 'service_status',
                    label: 'Statuts des services',
                    labelCol: 24,
                    labelIcon: 'cube',
                    component: 'multiSelect',
                    placeholder: 'Selectionner des status',
                    options: ORDERS_STATUSES,
                    withStatus: false,
                },
                {
                    name: 'total_gross',
                    label: 'Montant',
                    labelCol: 24,
                    labelIcon: 'coins',
                    component: 'slider',
                    withStatus: false,
                    range: [0, 10000],
                },
            ],
            fastSearch: [
                {
                    name: 'search',
                    placeholder: 'recherche rapide (défunt, ville, …)',
                    component: 'search',
                    withStatus: false,
                },
            ],
        },
        actual: {},
    },

    cursor: {
        start: null,
        end: null,
    },
}

const funeralPlanningSlice = createSlice({
    name: 'funeralPlanning',
    initialState,
    reducers: {
        // Filters
        setFuneralPlanningFilters: setListActualFilter,
        resetFuneralPlanningFilters: resetListActualFilter,

        // FORM OPTIONS
        setFuneralPlanningAgentsOptions: setOptionsToAgentField,
        setFuneralPlanningAgenciesOptions: setOptionsToAgencyField,

        // Cursor
        setFuneralPlanningCursor: (state, action) => {
            state.cursor = {
                start: action.payload.start,
                end: action.payload.end,
            }
        },
    },
})

export const {
    // Select options
    setFuneralPlanningAgentsOptions,
    setFuneralPlanningAgenciesOptions,

    // FILTERS
    setFuneralPlanningFilters,
    resetFuneralPlanningFilters,

    setFuneralPlanningCursor,
} = funeralPlanningSlice.actions

export default funeralPlanningSlice.reducer

// Root selector
const funeralPlanningSelector = (state) => state.funeralPlanning

export const selectFuneralPlanningFieldsFilters = createSelector(
    funeralPlanningSelector,
    (funeralPlanningState) => funeralPlanningState.filters.fields
)

export const selectFuneralPlanningCursor = createSelector(
    funeralPlanningSelector,
    (funeralPlanningState) => funeralPlanningState.cursor
)

export const selectFuneralPlanningFilters = createSelector(
    funeralPlanningSelector,
    (funeralPlanningState) => {
        return funeralPlanningState.filters.actual
    }
)

export const selectFuneralPlanningNumberOfActualFilters = createSelector(
    funeralPlanningSelector,
    (funeralPlanningState) => {
        const countWithoutOrderingAndSearch = _.omit(funeralPlanningState.filters?.actual, [
            'search',
        ])

        // Get number of filter who's value is not empty or null and object filtered
        const countWithoutEmptyArrayOrNullishValue = Object.keys(
            _.omitBy(
                countWithoutOrderingAndSearch,
                (value) => value?.length === 0 || _.isNil(value)
            )
        ).length

        return countWithoutEmptyArrayOrNullishValue
    }
)
