import _ from 'lodash'
import * as Sentry from '@sentry/nextjs'

export const checkPermissions = (userPermissions, permissionsRequired) => {
    if (permissionsRequired.includes('all')) {
        return true
    }

    if (_.isNil(userPermissions)) {
        return true
    }

    let isAuthorized = false

    // Check if user has permissions
    permissionsRequired.forEach(([permissionName, permissionAction]) => {
        let modelPermissions = _.get(userPermissions, permissionName)

        if (modelPermissions === undefined) {
            Sentry.captureException(
                new Error(`Error: Model not found in userPermissions ${permissionsRequired}`)
            )

            return
        }

        let actionPermissions = _.find(modelPermissions, { name: permissionAction })

        if (actionPermissions === undefined) {
            Sentry.captureException(
                new Error(`Error: Action not found in userPermissions ${permissionsRequired}`)
            )
            return
        }

        if (actionPermissions.value) {
            isAuthorized = true
        }
    })

    return isAuthorized
}

export const addDisabledToFields = (fields, authState) => {
    return fields
    // Break if no fields
    // if (!fields) {
    //     return fields
    // }

    // // Clone fields to avoid mutation
    // let newFieldsList = _.cloneDeep(fields)

    // // Check if user is authorized to edit fields
    // const isAuthorized = checkPermissions?.(
    //     authState.permissions,
    //     newFieldsList['requiredPermissions']
    // )

    // const loopOnFields = (fields, key_path = []) => {

    //     _.forEach(fields, (field, field_index) => {

    //         // TODO: Uncomment this
    //         if (!('disabled' in field && field['disabled']) && !isAuthorized) {
    //             _.set(newFieldsList, [...key_path, field_index, 'disabled'], true)
    //         }
    //     })
    // }

    // const loopOnParts = (parts, key_path = []) => {

    //     _.forEach(parts, (part, part_key) => {

    //         if (part_key === 'requiredPermissions') {
    //             return
    //         }

    //         if (_.isArray(part)) {

    //             loopOnFields(part, [...key_path, part_key])
    //         } else if (_.isObject(part)) {
    //             // If part is an object, recursively loop on it
    //             loopOnParts(part, [...key_path, part_key])
    //         }
    //     })
    // }

    // loopOnParts(newFieldsList)

    // return newFieldsList
}
