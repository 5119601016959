import {
    resetListActualFilter,
    setListActualFilter,
    setListPagination,
    setOptionsToGroupField,
    setTotalInPagination,
} from '@redux/common'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState = {
    addFields: {
        details: [
            {
                name: 'username',
                label: 'Identifiant',
                placeholder: 'Identifiant',
                component: 'text',
            },
            {
                name: 'firstname',
                label: 'Prénom',
                placeholder: 'Prénom',
                component: 'text',
            },
            {
                name: 'lastname',
                label: 'Nom',
                placeholder: 'Nom',
                component: 'text',
            },
            {
                name: 'email',
                label: 'Email',
                placeholder: 'mon-entreprise@mail.me',
                component: 'text',
            },
            {
                name: ['funovia', 'phone'],
                label: 'Tél.',
                placeholder: '01 02 03 04 05',
                component: 'phone',
            },
        ],
    },
    editFields: {
        details: [
            {
                name: 'username',
                label: 'Identifiant',
                placeholder: 'Identifiant',
                component: 'text',
                disabled: true,
            },
            {
                name: 'firstname',
                label: 'Prénom',
                placeholder: 'Prénom',
                component: 'text',
            },
            {
                name: 'lastname',
                label: 'Nom',
                placeholder: 'Nom',
                component: 'text',
            },
            {
                name: 'email',
                label: 'Email',
                placeholder: 'mon-entreprise@mail.me',
                component: 'text',
            },
            {
                name: ['funovia', 'phone'],
                label: 'Tél.',
                placeholder: '01 02 03 04 05',
                component: 'phone',
            },
        ],
    },

    filters: {
        fields: {
            fastSearch: [
                {
                    name: 'search',
                    placeholder: 'recherche rapide (Prénom, Nom, …)',
                    component: 'search',
                    withStatus: false,
                },
            ],
            drawer: [
                {
                    name: 'group',
                    labelCol: 24,
                    labelIcon: 'users',
                    label: 'Groupes',
                    placeholder: 'Sélectionnez un groupe',
                    component: 'multiSelect',
                    options: [],
                    withStatus: false,
                },
            ],
        },
        actual: {},
    },

    pagination: {
        page: 1,
        size: 15,
        total: 0,
    },
}

const funoviaUserSlice = createSlice({
    name: 'funoviaUser',
    initialState,
    reducers: {
        // FILTERS
        setFunoviaUsersFilters: setListActualFilter,
        resetFunoviaUsersFilters: resetListActualFilter,

        // PAGINATION
        setFunoviaUsersPagination: setListPagination,
        setFunoviaUsersTotalLineToPagination: setTotalInPagination,

        // SET OPTIONS:
        setGroupsOptions: setOptionsToGroupField,
    },
})

export const {
    // FILTERS
    setFunoviaUsersFilters,
    resetFunoviaUsersFilters,

    // PAGINATION
    setFunoviaUsersPagination,
    setFunoviaUsersTotalLineToPagination,

    // SET OPTIONS
    setGroupsOptions,
} = funoviaUserSlice.actions
export default funoviaUserSlice.reducer

// Root selector
const funoviaUserSelector = (state) => state.funoviaUser

// FILTERS
export const selectFunoviaUsersFieldsFilters = createSelector(
    funoviaUserSelector,
    (funoviaUserState) => funoviaUserState.filters.fields
)

export const selectFunoviaUsersFilters = createSelector(
    funoviaUserSelector,
    (funoviaUserState) => funoviaUserState.filters.actual
)

export const selectFunoviaUsersNumberOfActualFilters = createSelector(
    funoviaUserSelector,
    (funoviaUserState) => {
        const countWithoutOrderingAndSearch = _.omit(funoviaUserState.filters?.actual, [
            'search',
            'ordering',
        ])

        // Get number of filter who's value is not empty or null and object filtered
        const countWithoutEmptyArrayOrNullishValue = Object.keys(
            _.omitBy(
                countWithoutOrderingAndSearch,
                (value) => value?.length === 0 || _.isNil(value)
            )
        ).length

        return countWithoutEmptyArrayOrNullishValue
    }
)

// CREATE
export const selectFunoviaUserAddFields = createSelector(
    funoviaUserSelector,
    (funoviaUserState) => funoviaUserState.addFields
)

// EDIT
export const selectFunoviaUserEditFields = createSelector(
    funoviaUserSelector,
    (funoviaUserState) => funoviaUserState.editFields
)
// PAGINATION
export const selectFunoviaUsersPagination = createSelector(
    funoviaUserSelector,
    (funoviaUserState) => funoviaUserState.pagination
)

export const selectFunoviaUsersParamsPagination = createSelector(
    funoviaUserSelector,
    (funoviaUserState) => _.omit(funoviaUserState.pagination, ['total'])
)
