import { resetProcedureAddDataForm, setProceduresTotalLineToPagination } from './procedureSlice'
import { resetAddressBook } from '@redux/features/addressBook'
import { adminApi } from '@redux/rtk/api'
import { notification } from 'antd'
import * as Sentry from '@sentry/nextjs'

const proceduresApi = adminApi.injectEndpoints({
    endpoints: (builder) => ({
        getProcedures: builder.query({
            query: (params) => ({
                url: `admin/service_procedures/`,
                method: 'GET',
                params,
            }),
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result?.results?.map(({ id }) => ({ type: 'Procedure', id })),
                          { type: 'Procedure', id: 'LIST' },
                      ]
                    : [{ type: 'Procedure', id: 'LIST' }],

            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const result = await queryFulfilled

                    const { data } = result

                    dispatch(setProceduresTotalLineToPagination(data.count))
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
        }),

        createProcedure: builder.mutation({
            query: (procedure) => ({
                url: `admin/service_procedures/`,
                method: 'POST',
                body: procedure,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Funeral' }],

            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled

                    dispatch(resetAddressBook())
                    dispatch(resetProcedureAddDataForm())
                } catch (err) {}
            },
        }),

        getProcedure: builder.query({
            query: (id) => ({
                url: `admin/service_procedures/${id}`,
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: 'Procedure', id }],
        }),

        updateProcedure: builder.mutation({
            query: ({ data, id }) => ({
                url: `admin/service_procedures/${id}/`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Procedure', id: arg.id }],

            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled

                    const notificationOptions = {
                        message: 'Procédure modifiée avec succès',
                        duration: 2,
                    }
                    notification.success(notificationOptions)
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
        }),

        deleteProcedureFile: builder.mutation({
            query: (id) => ({
                url: `admin/service_procedures/${id}/derogation-file/`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Procedure', id: arg.id }],
        }),

        updateProcedureStatus: builder.mutation({
            query: ({ data, id }) => ({
                url: `admin/service_procedures/${id}/status/`,
                method: 'PATCH',
                body: data,
            }),

            invalidatesTags: (result, error, arg) => [
                { type: 'Procedure', id: arg.id },
                { type: 'Procedure', id: 'LIST' },
            ],

            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled

                    notification.success({ message: 'Statut mis à jours avec succès', duration: 2 })
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
        }),

        updateProcedureResponsible: builder.mutation({
            query: ({ data, id }) => ({
                url: `admin/service_procedures/${id}/responsible/`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Procedure', id: arg.id },
                { type: 'Procedure', id: 'LIST' },
            ],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled

                    notification.success({
                        message: 'Responsable mis à jours avec succès',
                        duration: 2,
                    })
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
        }),

        deleteProcedure: builder.mutation({
            query: (id) => ({
                url: `admin/service_procedures/${id}/`,
                method: 'DELETE',
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled

                    notification.success({
                        message: 'Service démarche supprimé',
                        duration: 1,
                    })
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
            invalidatesTags: (result, error, arg) => [
                { type: 'Procedure', id: arg.id },
                { type: 'Funeral', id: arg.id },
            ],
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetProceduresQuery,
    useGetProcedureQuery,
    useUpdateProcedureMutation,
    useCreateProcedureMutation,
    useDeleteProcedureFileMutation,
    useUpdateProcedureResponsibleMutation,
    useUpdateProcedureStatusMutation,
    useDeleteProcedureMutation,
} = proceduresApi
