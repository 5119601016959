const FRONT_URL = process.env.NEXT_PUBLIC_FRONT_URL
const VERSION = 'v1'
const MODE = process.env.NEXT_PUBLIC_API_MODE
const INSTANCE = process.env.NEXT_PUBLIC_API_INSTANCE
const API_ROUTE = process.env.NEXT_PUBLIC_API_URL

const PATHNAME_TO_DEFAULT_RESET_FIELDS = [
    '/orders/convoys/create',
    '/orders/transfers/create',
    '/orders/embalmings/create',
    '/orders/procedures/create',
]

const CONVOY_TEAM_TRANSLATION = {
    bearer: 'Porteur',
    master_of_ceremony: 'Maître de cérémonie',
}

export {
    FRONT_URL,
    VERSION,
    MODE,
    INSTANCE,
    API_ROUTE,
    PATHNAME_TO_DEFAULT_RESET_FIELDS,
    CONVOY_TEAM_TRANSLATION,
}
