import {
    resetListActualFilter,
    setListActualFilter,
    setListPagination,
    setTotalInPagination,
} from '@redux/common'
import { createSelector, createSlice } from '@reduxjs/toolkit'

const initialState = {
    editFields: {
        informations: [
            {
                name: 'name',
                label: 'Nom',
                placeholder: 'Nom du groupe',
                component: 'text',
                disabled: true,
            },
        ],
    },

    filters: {
        fields: {
            fastSearch: [
                {
                    name: 'search',
                    placeholder: 'recherche sur le nom',
                    component: 'search',
                    withStatus: false,
                },
            ],
        },
        actual: {},
    },

    pagination: {
        page: 1,
        size: 15,
        total: 0,
    },
}

const groupSlice = createSlice({
    name: 'group',
    initialState,
    reducers: {
        setGroupsFilters: setListActualFilter,
        resetGroupsFilters: resetListActualFilter,
        // Pagination
        setGroupsPagination: setListPagination,
        setGroupsTotalLineToPagination: setTotalInPagination,
    },
})

export const {
    setGroupsFilters,
    resetGroupsFilters,
    setGroupsPagination,
    setGroupsTotalLineToPagination,
} = groupSlice.actions
export default groupSlice.reducer

// ROOT SELECTOR
const groupSelector = (state) => state.group

// FORM FIELDS
export const selectGroupEditFields = createSelector(
    groupSelector,
    (groupState) => groupState.editFields
)

export const selectGroupAddFields = createSelector(
    groupSelector,
    (groupState) => groupState.addFields
)

// FILTERS FIELDS
export const selectGroupsFieldsFilters = createSelector(
    groupSelector,
    (groupState) => groupState.filters.fields
)

// FILTERS SELECTED
export const selectGroupsFilters = createSelector(
    groupSelector,
    (groupState) => groupState.filters.actual
)

// PAGINATION
export const selectGroupsPagination = createSelector(
    groupSelector,
    (groupState) => groupState.pagination
)
export const selectGroupsParamsPagination = createSelector(groupSelector, (groupState) =>
    _.omit(groupState.pagination, ['total'])
)
