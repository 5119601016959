import { ADDRESS_TYPE } from '@common/constants'
import { setListActualFilter } from '@redux/common'
import { createSelector, createSlice } from '@reduxjs/toolkit'

const initialState = {
    current: {},
    filters: {
        fields: [
            {
                name: 'type',
                label: 'Type',
                placeholder: 'Type de lieu',
                component: 'select',
                options: ADDRESS_TYPE,
                withStatus: false,
            },

            {
                name: 'city',
                label: '',
                placeholder: 'Code postal / Ville',
                component: 'text',
                withStatus: false,
            },
            {
                name: 'search',
                label: '',
                placeholder: 'Nom / Rue',
                component: 'text',
                withStatus: false,
            },
        ],

        actual: {},
    },
}

const addressBook = createSlice({
    name: 'addressBook',
    initialState,
    reducers: {
        setCurrentAddressBook: (state, action) => {
            // if (action.payload.key) {
            if (action.payload.key) {
                state.current[action.payload.key] = action.payload.value
            }
        },
        resetAddressBook: (state) => {
            state.current = {}
        },
        setActualFilters: setListActualFilter,
    },
})

export const { setActualFilters, setCurrentAddressBook, resetAddressBook } = addressBook.actions
export default addressBook.reducer

// Root selector
const addressBookSelector = (state) => state.addressBook

export const selectCurrentAddressBook = createSelector(
    [addressBookSelector, (state, key) => key],
    (items, key) => items?.current?.[key]
)

export const selectAddressBookFiltersFields = createSelector(
    addressBookSelector,
    (addressBookState) => addressBookState.filters.fields
)

export const selectDrawerActualFilters = createSelector(
    addressBookSelector,
    (addressBookState) => addressBookState.filters.actual
)
