import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const statisticals = createSlice({
    name: 'statisticals',
    initialState,
    reducers: {},
})

// export const {} = statisticals.actions
export default statisticals.reducer
