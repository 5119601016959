import { useRouter } from 'next/router'
import React from 'react'

import CustomerLayout from './CustomerLayout'
import AuthLayout from './AuthLayout'
import PrintLayout from './PrintLayout'

const Template = ({ children }) => {
    const router = useRouter()

    // Create a state to store the layout name (auth or customer)
    const [layoutName, setLayoutName] = React.useState('')

    React.useEffect(() => {
        // Define the layout name to use by url
        const defineLayoutName = (url) => {
            // Define the layout name by the url.
            if (url.startsWith('/auth/')) {
                setLayoutName('auth')
            }

            // If url contains with '/print', we use blank layout
            else if (url.includes('/print')) {
                setLayoutName('print')
            } else {
                setLayoutName('customer')
            }
        }
        // Call the function to define the layout name
        defineLayoutName(router.pathname)

        // When router change is completed allow to render the component
        const handleRouteChangeComplete = (url) => {
            // Call the function to define the layout name
            defineLayoutName(url)
        }

        router.events.on('routeChangeComplete', handleRouteChangeComplete)

        return () => {
            router.events.off('routeChangeComplete', handleRouteChangeComplete)
        }
    }, [router])

    // Choose the layout to use
    let Layout = ({ children }) => {
        return children
    }

    switch (layoutName) {
        case 'auth':
            Layout = AuthLayout
            break

        case 'customer':
            Layout = CustomerLayout
            break

        case 'print':
            Layout = PrintLayout
            break
    }

    return <Layout>{children}</Layout>
}

export default Template
