import { setIsAuth } from '@redux/features/auth'
import { setFunoviaUsersFilters, setFunoviaUsersPagination } from '@redux/features/personals/users'
import { setFuneralsFilters, setFuneralsPagination } from '@redux/features/funerals'
import { setTransfersFilters, setTransfersPagination } from '@redux/features/orders/transfers'
import { setProceduresFilters, setProceduresPagination } from '@redux/features/orders/procedures'
import { setEmbalmingsFilters, setEmbalmingsPagination } from '@redux/features/orders/embalmings'
import { setConvoysFilters, setConvoysPagination } from '@redux/features/orders/convoys'
import { setCompaniesFilters, setCompaniesPagination } from '@redux/features/companies'
import { setAgenciesFilters, setAgenciesPagination } from '@redux/features/agencies'
import { setAgentsFilters, setAgentsPagination } from '@redux/features/agents'

import { persistor } from '@redux/store'
import { createListenerMiddleware, isAnyOf, isRejectedWithValue } from '@reduxjs/toolkit'
import { notification } from 'antd'
import _ from 'lodash'
export const listenerMiddleware = createListenerMiddleware()
// Watch filter and set pagination page to 1 if filter change

const isAFiltersAction = isAnyOf(
    setFunoviaUsersFilters,
    setFuneralsFilters,
    setTransfersFilters,
    setProceduresFilters,
    setEmbalmingsFilters,
    setConvoysFilters,
    setCompaniesFilters,
    setAgenciesFilters,
    setAgentsFilters
)

listenerMiddleware.startListening({
    matcher: isAFiltersAction,

    effect: (action, listenerApi) => {
        switch (action.type) {
            case 'funoviaUser/setFunoviaUsersFilters':
                listenerApi.dispatch(setFunoviaUsersPagination(1))
                break
            case 'funeral/setFuneralsFilters':
                listenerApi.dispatch(setFuneralsPagination(1))
                break
            case 'transfer/setTransfersFilters':
                listenerApi.dispatch(setTransfersPagination(1))
                break
            case 'procedure/setProceduresFilters':
                listenerApi.dispatch(setProceduresPagination(1))
                break
            case 'convoy/setConvoysFilters':
                listenerApi.dispatch(setConvoysPagination(1))
                break
            case 'company/setCompaniesFilters':
                listenerApi.dispatch(setCompaniesPagination(1))
                break
            case 'agency/setAgenciesFilters':
                listenerApi.dispatch(setAgenciesPagination(1))
                break
            case 'agent/setAgentsFilters':
                listenerApi.dispatch(setAgentsPagination(1))
                break
            case 'embalming/setEmbalmingsFilters':
                listenerApi.dispatch(setEmbalmingsPagination(1))
                break
            default:
                break
        }
    },
})

/**
 * Show notification on error captured
 * TODO: setup sentry here
 */
export const rtkQueryErrorLogger = (api) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        if (action.payload?.status === 401) {
            localStorage.removeItem('funovia-admin-token')
            persistor.flush()
            persistor.purge()
            api.dispatch(setIsAuth(false))
        } else {
            if (!_.isNil(action) && action?.payload && action?.payload?.data) {
                for (const [key, value] of Object.entries(action?.payload?.data)) {
                    notification.error({
                        placement: 'top',
                        bottom: 50,
                        duration: 3,
                        message: value?.[0] || 'Une erreur est survenue',
                    })
                }
            }
        }
    }

    return next(action)
}
