import { adminApi } from '@redux/rtk/api'

import { setFuneralsTotalLineToPagination } from './funeralSlice'

const apiRoutePrefix = 'admin/funerals/'
import * as Sentry from '@sentry/nextjs'

const funeralApi = adminApi.injectEndpoints({
    endpoints: (builder) => ({
        getFunerals: builder.query({
            query: (params) => {
                return {
                    url: `${apiRoutePrefix}`,
                    method: 'GET',
                    params,
                }
            },
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result?.results?.map(({ id }) => ({ type: 'Funeral', id })),
                          { type: 'Funeral', id: 'LIST' },
                      ]
                    : [{ type: 'Funeral', id: 'LIST' }],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const result = await queryFulfilled

                    const { data } = result

                    dispatch(setFuneralsTotalLineToPagination(data.count))
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
        }),
        getFuneralsArchived: builder.query({
            query: (params) => {
                return {
                    url: `${apiRoutePrefix}archived/`,
                    method: 'GET',
                    params,
                }
            },
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result?.results?.map(({ id }) => ({ type: 'Funeral', id })),
                          { type: 'Funeral', id: 'LIST_ARCHIVED' },
                      ]
                    : [{ type: 'Funeral', id: 'LIST_ARCHIVED' }],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const result = await queryFulfilled

                    const { data } = result

                    dispatch(setFuneralsTotalLineToPagination(data.count))
                } catch (error) {
                    Sentry.captureException(error)
                }
            },
        }),
        getFuneralsNoPage: builder.query({
            query: (params) => ({
                url: `${apiRoutePrefix}`,
                method: 'GET',
                params: {
                    no_page: true,
                    ...(params || {}),
                },
            }),
        }),
        getFuneral: builder.query({
            query: (id) => ({
                url: `${apiRoutePrefix}${id}/`,
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: 'Funeral', id }],
        }),
        getFuneralHistories: builder.query({
            query: (id) => ({
                url: `${apiRoutePrefix}${id}/histories/`,
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: 'Funeral', id: `${id}_HISTORIES` }],
        }),
        createFuneral: builder.mutation({
            query: (data) => ({
                url: `${apiRoutePrefix}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (result, error, body) => [
                { type: 'Funeral', id: 'LIST' },
                { type: 'Funeral', id: 'LIST_ARCHIVED' },
            ],
        }),
        editFuneral: builder.mutation({
            query: ({ data, id }) => ({
                url: `${apiRoutePrefix}${id}/`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: (result, error, body) => [
                { type: 'Funeral', id: body.id },
                { type: 'Funeral', id: `${body.id}_HISTORIES` },
                { type: 'Funeral', id: 'LIST' },
                { type: 'Funeral', id: 'LIST_NO_PAGE' },
            ],
        }),
        deleteFuneral: builder.mutation({
            query: (id) => ({
                url: `${apiRoutePrefix}${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, id) => [{ type: 'Funeral', id: 'LIST' }],
        }),
    }),

    overrideExisting: true,
})

export const {
    useGetFuneralsQuery,
    useGetFuneralQuery,
    useGetFuneralsNoPageQuery,
    useGetFuneralsArchivedQuery,
    useGetFuneralHistoriesQuery,
    useCreateFuneralMutation,
    useEditFuneralMutation,
    useDeleteFuneralMutation,
} = funeralApi
